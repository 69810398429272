<template>
  <span
    v-if="visible"
    class="indicator"
  >{{ label }}</span>
</template>

<script>
import AposFieldMetaUtilsMixin from 'Modules/@apostrophecms/doc/mixins/AposFieldMetaUtilsMixin';
export default {
  name: 'TestMetaFieldIndicator',
  mixins: [ AposFieldMetaUtilsMixin ],
  data() {
    return {
      label: 'Changed',
      visible: false
    };
  },
  mounted() {
    this.visible = this.isChanged();
  },
  methods: {
    isChanged() {
      this.label = 'Changed';
      if (this.meta.changed) {
        return true;
      };

      const config = this.field;

      // Arrays receive special treatment if changed key is not explicitly set.
      if (config.type === 'array') {
        // Never show it for inline arrays that are not tables
        if (config.inline && config.style !== 'table') {
          return false;
        }
      }

      // For arrays, areas, and all types having "items", try to match the
      // changed status of any of the underlying items.
      const meta = this.metaRaw || {};
      for (const item of this.items) {
        if (
          this.hasMeta(meta[item._id], this.namespace, 'changed', true)
        ) {
          this.label = 'Computed Changed';
          return true;
        }
      }

      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.indicator {
    display: inline-block;
    padding: 5px;
    border: 1px solid var(--a-base-8);
    border-radius: var(--a-border-radius);
    font-size: var(--a-type-tiny);
}
</style>
