<script>
import AposInputCheckboxes from 'Modules/@apostrophecms/schema/components/AposInputCheckboxes.vue';

export default {
  name: 'AposInputDataSetColumns',
  components: {
    AposInputCheckboxes
  },
  extends: AposInputCheckboxes,
  watch: {
    followingValues: {
      handler(newValue, oldValue) {
        if (oldValue !== newValue) {
          const [ dataSet ] = newValue?._dataSet || [];
          this.setChoices(dataSet);
        }
      }
    }
  },
  mounted() {
    this.setChoices(this.followingValues?._dataSet?.[0]);
  },
  methods: {
    setChoices(dataSet = {}) {
      this.choices = (dataSet?.data?.schema || [])
        .map(column => ({
          label: column.label,
          value: column.name
        }));
      this.field.choices = this.choices;
    }
  }
};
</script>
