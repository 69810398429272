export default () => {
  let once = false;

  apos.util.onReady(() => {
    if (!once) {
      once = true;
      apos.bus.$on('admin-menu-click', openOurConfirmDialog);
    }
  });

  async function openOurConfirmDialog(event) {
    if (event !== 'apos-import-export-translate-select') {
      return;
    }
    const modal = apos.modal.modals.find(m => m.componentName === 'AposImportExportTranslationModal');
    if (!modal) {
      console.error('Could not find modal "AposImportExportTranslationModal"');
      return;
    }

    const isExport = await apos.modal.execute(apos.modal.components.confirm, {
      content: {
        affirmativeLabel: 'importExportTranslation:confirmExportLabel',
        negativeLabel: 'importExportTranslation:confirmImportLabel',
        heading: 'importExportTranslation:confirmHeading',
        description: 'importExportTranslation:confirmDescription',
        icon: false,
        theme: 'none'
      },
      mode: 'confirm',
      options: {
        tiny: true,
        hasCloseButton: true
      }
    });

    if (isExport === null) {
      return;
    }

    const result = await apos.modal.execute(modal.componentName, {
      ...modal.props,
      mode: isExport ? 'export' : 'import'
    });

    if (isExport && result?.data) {
      openDownloadLink(result.data);
    }

    function openDownloadLink(data) {
      const id = 'apos-import-export-download-link';
      // remove previous download links
      document.getElementById(id)?.remove();
      const url = window.URL.createObjectURL(new Blob([ data ]));
      const link = document.createElement('a');
      link.id = id;
      link.href = url;
      link.style.display = 'none';
      link.setAttribute('download', `${apos.locale}.json`);
      document.body.appendChild(link);
      link.click();
    }
  }
};
