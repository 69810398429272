<script>
import AposDocsManager from 'Modules/@apostrophecms/piece-type/components/AposDocsManager.vue';

export default {
  components: {
    AposDocsManager
  },
  extends: AposDocsManager,
  methods: {
    async create() {
      await apos.modal.execute(
        this.moduleOptions.components.importModal,
        {
          title: this.$t({
            key: 'apostropheDataSet:importTitle',
            type: this.$t(this.moduleLabels.singular)
          }),
          description: this.$t({
            key: 'apostropheDataSet:importDescription',
            allowedExtensions: this.moduleOptions.allowedExtensions
          }),
          allowedExtensions: this.moduleOptions.allowedExtensions.join(','),
          confirmationButton: this.$t('apostropheDataSet:importConfirmationButton'),
          messages: {
            progress: this.$t({
              key: 'apostropheDataSet:importProgress',
              type: this.$t(this.moduleLabels.singular)
            })
          },
          moduleAction: this.moduleOptions.action,
          action: 'import',
          labels: this.moduleLabels
        }
      );
    }
  }
};
</script>
