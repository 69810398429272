<template>
  <div
    v-if="displayCustomBar"
    class="apos-admin-bar__control-set apos-admin-bar__control-set__custom-bar"
  >
    <span>{{ projectName }} - {{ $t('customBarTitle') }}</span>
  </div>
</template>

<script>
export default {
  name: 'CustomBar',
  props: {
    projectName: {
      type: String,
      required: true
    }
  },
  computed: {
    moduleOptions() {
      return window.apos.adminBar;
    },
    displayCustomBar() {
      return this.moduleOptions.displayCustomBar;
    }
  }
};
</script>

<style lang="scss" scoped>
.apos-admin-bar__control-set__custom-bar {
  padding: 10px 20px;
  width: auto;
  justify-content: center;
  color: white;
  background-color: rgba(0, 132, 255, 0.85);
}
</style>
