<template>
  <AposInputWrapper
    :field="field"
    :error="effectiveError"
    :uid="uid"
    :modifiers="[...modifiers, 'full-width']"
    :display-options="{ helpTooltip: true}"
  >
    <template #body>
      <AposPermissionGrid
        :api-params="{ permissionsByType: next, admin: followingValues.admin }"
        :group-title="followingValues.title"
        :read-only="followingValues.admin"
        @change="change"
      />
    </template>
  </AposInputWrapper>
</template>

<script>
import AposInputMixin from 'Modules/@apostrophecms/schema/mixins/AposInputMixin';

export default {
  name: 'AposInputRolesByType',
  mixins: [ AposInputMixin ],
  data() {
    return {
      // Next should consistently be an array.
      next: (this.modelValue && Array.isArray(this.modelValue.data))
        ? this.modelValue.data
        : (this.field.def || [])
    };
  },
  methods: {
    validate(value) {
      if (this.field.required && !value.length) {
        return 'required';
      }
      return false;
    },
    change(checked) {
      this.next = Object.entries(checked).map(([ type, permissions ]) => {
        return {
          type,
          permissions: Object.entries(permissions)
            .filter(([ _, value ]) => value === true)
            .map(([ permName ]) => permName)
        };
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
