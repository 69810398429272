<template>
  <AposInputWrapper
    :field="field"
    :uid="uid"
    :modifiers="['margin-none']"
  >
    <template #body>
      <div class="apos-field__container">
        <AposCheckbox
          v-apos-tooltip="getRequiredTooltip()"
          :model-value="next || next === null"
          tabindex="-1"
          :field="{
            ...field,
            readOnly: isDisabled()
          }"
          :choice="{
            value: next,
            indeterminate: next === null,
            triggerIndeterminateEvent: true
          }"
          @update:model-value="change"
        />
      </div>
    </template>
  </AposInputWrapper>
</template>

<script>
import AposInputMixin from 'Modules/@apostrophecms/schema/mixins/AposInputMixin';

export default {
  name: 'AposInputNamedPermission',
  mixins: [ AposInputMixin ],
  data() {
    return {
      requiresAnotherField: (!this.field.rowToggle && this.field.requires) &&
          Object.hasOwn(this.followingValues, this.field.requires)
    };
  },
  watch: {
    followingValues: {
      deep: true,
      handler(val, oldVal = {}) {
        if (this.field.rowToggle) {
          this.updateRowToggle(val, oldVal);
        } else {
          this.updatePermission(val, oldVal);
        }
      }
    }
  },
  mounted() {
    if (this.field.rowToggle) {
      this.next = this.getToggleValue(this.followingValues);
    }
  },
  methods: {
    updatePermission(val, oldVal) {
      if (
        typeof val.toggleField === 'boolean' &&
        val.toggleField !== oldVal.toggleField
      ) {
        this.next = val.toggleField;
      }

      if (
        this.requiresAnotherField &&
        val[this.field.requires] !== oldVal[this.field.requires] &&
        !val[this.field.requires]
      ) {
        this.next = val[this.field.requires];
      }
    },
    updateRowToggle(val, oldVal) {
      const someDiffer = Object.entries(val)
        .some(([ field, fieldValue ]) => oldVal[field] !== fieldValue);

      if (someDiffer) {
        this.next = this.getToggleValue(val);
      }
    },
    getToggleValue(followedValues) {
      const values = Object.values(followedValues);
      if (values.every((v) => v === true)) {
        return true;
      }

      if (values.every((v) => v === false)) {
        return false;
      }

      return null;
    },
    isDisabled() {
      if (!this.requiresAnotherField) {
        return false;
      }

      return !this.followingValues[this.field.requires];
    },
    validate() {
      return false;
    },
    change() {
      this.next = this.next === null ? false : !this.next;
    },
    getRequiredTooltip() {
      if (!this.isDisabled()) {
        return false;
      }

      const key = this.$t(
        this.field.permissionsLabels[this.field.requires] ||
        this.field.requires
      );
      return this.buildTooltipHtml(
        this.$t('apostropheAdvancedPermission:requiresAnotherPermission'),
        this.$t(
          'apostropheAdvancedPermission:requiresAnotherPermissionMsg',
          { requiredPerm: key.toLowerCase() }
        )
      );
    },
    buildTooltipHtml(title, msg) {
      return `<p><strong>${title}</strong></p><p>${msg}</p>`;
    }
  }
};
</script>

<style lang="scss" scoped>
  .apos-field__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
