import 'Modules/@apostrophecms/ui/scss/global/import-all.scss';
import emitter from 'tiny-emitter/instance';
window.apos.bus = {
  $on: (...args) => emitter.on(...args),
  $once: (...args) => emitter.once(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args)
};
import AccountBoxIcon from '@apostrophecms/vue-material-design-icons/AccountBox.vue';
import AlertCircleIcon from '@apostrophecms/vue-material-design-icons/AlertCircle.vue';
import AlphaXIcon from '@apostrophecms/vue-material-design-icons/AlphaX.vue';
import AnchorIcon from '@apostrophecms/vue-material-design-icons/Anchor.vue';
import AppleKeyboardCapsIcon from '@apostrophecms/vue-material-design-icons/AppleKeyboardCaps.vue';
import AppleKeyboardCommandIcon from '@apostrophecms/vue-material-design-icons/AppleKeyboardCommand.vue';
import AppleKeyboardControlIcon from '@apostrophecms/vue-material-design-icons/AppleKeyboardControl.vue';
import AppleKeyboardOptionIcon from '@apostrophecms/vue-material-design-icons/AppleKeyboardOption.vue';
import AppleKeyboardShiftIcon from '@apostrophecms/vue-material-design-icons/AppleKeyboardShift.vue';
import ArchiveArrowDownIcon from '@apostrophecms/vue-material-design-icons/ArchiveArrowDown.vue';
import ArchiveArrowUpIcon from '@apostrophecms/vue-material-design-icons/ArchiveArrowUp.vue';
import ArrowCollapseVerticalIcon from '@apostrophecms/vue-material-design-icons/ArrowCollapseVertical.vue';
import ArrowDownIcon from '@apostrophecms/vue-material-design-icons/ArrowDown.vue';
import ArrowExpandVerticalIcon from '@apostrophecms/vue-material-design-icons/ArrowExpandVertical.vue';
import ArrowLeftIcon from '@apostrophecms/vue-material-design-icons/ArrowLeft.vue';
import ArrowRightIcon from '@apostrophecms/vue-material-design-icons/ArrowRight.vue';
import ArrowUpIcon from '@apostrophecms/vue-material-design-icons/ArrowUp.vue';
import BinocularsIcon from '@apostrophecms/vue-material-design-icons/Binoculars.vue';
import CalendarIcon from '@apostrophecms/vue-material-design-icons/Calendar.vue';
import CellphoneIcon from '@apostrophecms/vue-material-design-icons/Cellphone.vue';
import CheckAllIcon from '@apostrophecms/vue-material-design-icons/CheckAll.vue';
import CheckBoldIcon from '@apostrophecms/vue-material-design-icons/CheckBold.vue';
import CheckCircleIcon from '@apostrophecms/vue-material-design-icons/CheckCircle.vue';
import CheckDecagramIcon from '@apostrophecms/vue-material-design-icons/CheckDecagram.vue';
import CheckboxBlankOutlineIcon from '@apostrophecms/vue-material-design-icons/CheckboxBlankOutline.vue';
import CheckboxMarkedIcon from '@apostrophecms/vue-material-design-icons/CheckboxMarked.vue';
import ChevronDownIcon from '@apostrophecms/vue-material-design-icons/ChevronDown.vue';
import ChevronLeftIcon from '@apostrophecms/vue-material-design-icons/ChevronLeft.vue';
import ChevronRightIcon from '@apostrophecms/vue-material-design-icons/ChevronRight.vue';
import ChevronUpIcon from '@apostrophecms/vue-material-design-icons/ChevronUp.vue';
import CircleIcon from '@apostrophecms/vue-material-design-icons/Circle.vue';
import CircleMediumIcon from '@apostrophecms/vue-material-design-icons/CircleMedium.vue';
import ClipboardPlusIcon from '@apostrophecms/vue-material-design-icons/ClipboardPlus.vue';
import ClipboardPlusOutlineIcon from '@apostrophecms/vue-material-design-icons/ClipboardPlusOutline.vue';
import ClockIcon from '@apostrophecms/vue-material-design-icons/Clock.vue';
import CloseCircleIcon from '@apostrophecms/vue-material-design-icons/CloseCircle.vue';
import CloseIcon from '@apostrophecms/vue-material-design-icons/Close.vue';
import CloudUploadIcon from '@apostrophecms/vue-material-design-icons/CloudUpload.vue';
import CodeTagsIcon from '@apostrophecms/vue-material-design-icons/CodeTags.vue';
import CogIcon from '@apostrophecms/vue-material-design-icons/Cog.vue';
import ContentCopyIcon from '@apostrophecms/vue-material-design-icons/ContentCopy.vue';
import ContentCutIcon from '@apostrophecms/vue-material-design-icons/ContentCut.vue';
import CursorDefaultClickIcon from '@apostrophecms/vue-material-design-icons/CursorDefaultClick.vue';
import DeleteIcon from '@apostrophecms/vue-material-design-icons/Delete.vue';
import DotsVerticalIcon from '@apostrophecms/vue-material-design-icons/DotsVertical.vue';
import AppsIcon from '@apostrophecms/vue-material-design-icons/Apps.vue';
import EyeIcon from '@apostrophecms/vue-material-design-icons/Eye.vue';
import FileDocumentIcon from '@apostrophecms/vue-material-design-icons/FileDocument.vue';
import FileTreeIcon from '@apostrophecms/vue-material-design-icons/FileTree.vue';
import FlareIcon from '@apostrophecms/vue-material-design-icons/Flare.vue';
import FormatAlignCenterIcon from '@apostrophecms/vue-material-design-icons/FormatAlignCenter.vue';
import FormatAlignJustifyIcon from '@apostrophecms/vue-material-design-icons/FormatAlignJustify.vue';
import FormatAlignLeftIcon from '@apostrophecms/vue-material-design-icons/FormatAlignLeft.vue';
import FormatAlignRightIcon from '@apostrophecms/vue-material-design-icons/FormatAlignRight.vue';
import FormatBoldIcon from '@apostrophecms/vue-material-design-icons/FormatBold.vue';
import FormatFontIcon from '@apostrophecms/vue-material-design-icons/FormatFont.vue';
import FormatItalicIcon from '@apostrophecms/vue-material-design-icons/FormatItalic.vue';
import FormatListBulletedIcon from '@apostrophecms/vue-material-design-icons/FormatListBulleted.vue';
import FormatListNumberedIcon from '@apostrophecms/vue-material-design-icons/FormatListNumbered.vue';
import FormatQuoteCloseIcon from '@apostrophecms/vue-material-design-icons/FormatQuoteClose.vue';
import FormatStrikethroughVariantIcon from '@apostrophecms/vue-material-design-icons/FormatStrikethroughVariant.vue';
import FormatSubscriptIcon from '@apostrophecms/vue-material-design-icons/FormatSubscript.vue';
import FormatSuperscriptIcon from '@apostrophecms/vue-material-design-icons/FormatSuperscript.vue';
import FormatTextIcon from '@apostrophecms/vue-material-design-icons/FormatText.vue';
import FormatUnderlineIcon from '@apostrophecms/vue-material-design-icons/FormatUnderline.vue';
import HelpCircleIcon from '@apostrophecms/vue-material-design-icons/HelpCircle.vue';
import ImageEditOutlineIcon from '@apostrophecms/vue-material-design-icons/ImageEditOutline.vue';
import ImageIcon from '@apostrophecms/vue-material-design-icons/Image.vue';
import ImageSizeSelectActualIcon from '@apostrophecms/vue-material-design-icons/ImageSizeSelectActual.vue';
import InformationIcon from '@apostrophecms/vue-material-design-icons/Information.vue';
import InformationOutlineIcon from '@apostrophecms/vue-material-design-icons/InformationOutline.vue';
import InstagramIcon from '@apostrophecms/vue-material-design-icons/Instagram.vue';
import KeyboardBackspaceIcon from '@apostrophecms/vue-material-design-icons/KeyboardBackspace.vue';
import KeyboardEscIcon from '@apostrophecms/vue-material-design-icons/KeyboardEsc.vue';
import KeyboardF1Icon from '@apostrophecms/vue-material-design-icons/KeyboardF1.vue';
import KeyboardF10Icon from '@apostrophecms/vue-material-design-icons/KeyboardF10.vue';
import KeyboardF11Icon from '@apostrophecms/vue-material-design-icons/KeyboardF11.vue';
import KeyboardF12Icon from '@apostrophecms/vue-material-design-icons/KeyboardF12.vue';
import KeyboardF2Icon from '@apostrophecms/vue-material-design-icons/KeyboardF2.vue';
import KeyboardF3Icon from '@apostrophecms/vue-material-design-icons/KeyboardF3.vue';
import KeyboardF4Icon from '@apostrophecms/vue-material-design-icons/KeyboardF4.vue';
import KeyboardF5Icon from '@apostrophecms/vue-material-design-icons/KeyboardF5.vue';
import KeyboardF6Icon from '@apostrophecms/vue-material-design-icons/KeyboardF6.vue';
import KeyboardF7Icon from '@apostrophecms/vue-material-design-icons/KeyboardF7.vue';
import KeyboardF8Icon from '@apostrophecms/vue-material-design-icons/KeyboardF8.vue';
import KeyboardF9Icon from '@apostrophecms/vue-material-design-icons/KeyboardF9.vue';
import KeyboardReturnIcon from '@apostrophecms/vue-material-design-icons/KeyboardReturn.vue';
import KeyboardSpaceIcon from '@apostrophecms/vue-material-design-icons/KeyboardSpace.vue';
import KeyboardTabIcon from '@apostrophecms/vue-material-design-icons/KeyboardTab.vue';
import LabelIcon from '@apostrophecms/vue-material-design-icons/Label.vue';
import LightbulbOnIcon from '@apostrophecms/vue-material-design-icons/LightbulbOn.vue';
import LinkIcon from '@apostrophecms/vue-material-design-icons/Link.vue';
import ListStatusIcon from '@apostrophecms/vue-material-design-icons/ListStatus.vue';
import LockIcon from '@apostrophecms/vue-material-design-icons/Lock.vue';
import MagnifyIcon from '@apostrophecms/vue-material-design-icons/Magnify.vue';
import MapMarkerIcon from '@apostrophecms/vue-material-design-icons/MapMarker.vue';
import MenuDownIcon from '@apostrophecms/vue-material-design-icons/MenuDown.vue';
import MinusBoxIcon from '@apostrophecms/vue-material-design-icons/MinusBox.vue';
import MinusIcon from '@apostrophecms/vue-material-design-icons/Minus.vue';
import MonitorIcon from '@apostrophecms/vue-material-design-icons/Monitor.vue';
import PaperclipIcon from '@apostrophecms/vue-material-design-icons/Paperclip.vue';
import PencilIcon from '@apostrophecms/vue-material-design-icons/Pencil.vue';
import PhoneIcon from '@apostrophecms/vue-material-design-icons/Phone.vue';
import PlayBoxIcon from '@apostrophecms/vue-material-design-icons/PlayBox.vue';
import PlusIcon from '@apostrophecms/vue-material-design-icons/Plus.vue';
import RedoVariantIcon from '@apostrophecms/vue-material-design-icons/RedoVariant.vue';
import RefreshIcon from '@apostrophecms/vue-material-design-icons/Refresh.vue';
import ShapeIcon from '@apostrophecms/vue-material-design-icons/Shape.vue';
import SignTextIcon from '@apostrophecms/vue-material-design-icons/SignText.vue';
import TabletIcon from '@apostrophecms/vue-material-design-icons/Tablet.vue';
import TagIcon from '@apostrophecms/vue-material-design-icons/Tag.vue';
import TextBoxIcon from '@apostrophecms/vue-material-design-icons/TextBox.vue';
import TextBoxMultipleIcon from '@apostrophecms/vue-material-design-icons/TextBoxMultiple.vue';
import TextBoxRemoveIcon from '@apostrophecms/vue-material-design-icons/TextBoxRemove.vue';
import TrashCanIcon from '@apostrophecms/vue-material-design-icons/TrashCan.vue';
import TrashCanOutlineIcon from '@apostrophecms/vue-material-design-icons/TrashCanOutline.vue';
import TuneIcon from '@apostrophecms/vue-material-design-icons/Tune.vue';
import UndoVariantIcon from '@apostrophecms/vue-material-design-icons/UndoVariant.vue';
import UnfoldLessHorizontalIcon from '@apostrophecms/vue-material-design-icons/UnfoldLessHorizontal.vue';
import UnfoldMoreHorizontalIcon from '@apostrophecms/vue-material-design-icons/UnfoldMoreHorizontal.vue';
import VideoIcon from '@apostrophecms/vue-material-design-icons/Video.vue';
import ViewColumnIcon from '@apostrophecms/vue-material-design-icons/ViewColumn.vue';
import WaterOffIcon from '@apostrophecms/vue-material-design-icons/WaterOff.vue';
import WebIcon from '@apostrophecms/vue-material-design-icons/Web.vue';
import DownloadIcon from '@apostrophecms/vue-material-design-icons/Download.vue';
import UploadIcon from '@apostrophecms/vue-material-design-icons/Upload.vue';
import AccountGroupIcon from '@apostrophecms/vue-material-design-icons/AccountGroup.vue';
import DatabaseExportIcon from '@apostrophecms/vue-material-design-icons/DatabaseExport.vue';
import AccountIcon from '@apostrophecms/vue-material-design-icons/Account.vue';
import FormatColorHighlightIcon from '@apostrophecms/vue-material-design-icons/FormatColorHighlight.vue';
import TableIcon from '@apostrophecms/vue-material-design-icons/Table.vue';
import PaletteSwatchIcon from '@apostrophecms/vue-material-design-icons/PaletteSwatch.vue';
import EarthIcon from '@apostrophecms/vue-material-design-icons/Earth.vue';
import DatabaseCheckIcon from '@apostrophecms/vue-material-design-icons/DatabaseCheck.vue';
import TrayFullIcon from '@apostrophecms/vue-material-design-icons/TrayFull.vue';
import DatabaseImportIcon from '@apostrophecms/vue-material-design-icons/DatabaseImport.vue';
import FormSelectIcon from '@apostrophecms/vue-material-design-icons/FormSelect.vue';
import FormTextboxIcon from '@apostrophecms/vue-material-design-icons/FormTextbox.vue';
import FormTextareaIcon from '@apostrophecms/vue-material-design-icons/FormTextarea.vue';
import RadioboxMarkedIcon from '@apostrophecms/vue-material-design-icons/RadioboxMarked.vue';
import CheckboxMarkedOutlineIcon from '@apostrophecms/vue-material-design-icons/CheckboxMarkedOutline.vue';
import FileUploadOutlineIcon from '@apostrophecms/vue-material-design-icons/FileUploadOutline.vue';
import ToggleSwitchOutlineIcon from '@apostrophecms/vue-material-design-icons/ToggleSwitchOutline.vue';
import ArrowDecisionIcon from '@apostrophecms/vue-material-design-icons/ArrowDecision.vue';
import CreationIcon from '@apostrophecms/vue-material-design-icons/Creation.vue';
import PillarIcon from '@apostrophecms/vue-material-design-icons/Pillar.vue';
import FileTableBoxIcon from '@apostrophecms/vue-material-design-icons/FileTableBox.vue';
import ViewSplitVerticalIcon from '@apostrophecms/vue-material-design-icons/ViewSplitVertical.vue';
import PaletteIcon from '@apostrophecms/vue-material-design-icons/Palette.vue';
import PaletteAdvancedIcon from '@apostrophecms/vue-material-design-icons/PaletteAdvanced.vue';
import TranslateIcon from '@apostrophecms/vue-material-design-icons/Translate.vue';
import TextIcon from '@apostrophecms/vue-material-design-icons/Text.vue';
import AposI18nLocalize from "./@apostrophecms/i18n/apos/components/AposI18nLocalize.vue";
import AposArrayEditor from "./@apostrophecms/schema/apos/components/AposArrayEditor.vue";
import AposInputArea from "./@apostrophecms/schema/apos/components/AposInputArea.vue";
import AposInputArray from "./@apostrophecms/schema/apos/components/AposInputArray.vue";
import AposInputAttachment from "./@apostrophecms/schema/apos/components/AposInputAttachment.vue";
import AposInputBoolean from "./@apostrophecms/schema/apos/components/AposInputBoolean.vue";
import AposInputCheckboxes from "./@apostrophecms/schema/apos/components/AposInputCheckboxes.vue";
import AposInputDateAndTime from "./@apostrophecms/schema/apos/components/AposInputDateAndTime.vue";
import AposInputObject from "./@apostrophecms/schema/apos/components/AposInputObject.vue";
import AposInputPassword from "./@apostrophecms/schema/apos/components/AposInputPassword.vue";
import AposInputRadio from "./@apostrophecms/schema/apos/components/AposInputRadio.vue";
import AposInputRange from "./@apostrophecms/schema/apos/components/AposInputRange.vue";
import AposInputRelationship from "./@apostrophecms/schema/apos/components/AposInputRelationship.vue";
import AposInputSelect from "./@apostrophecms/schema/apos/components/AposInputSelect.vue";
import AposInputSlug from "./@apostrophecms/schema/apos/components/AposInputSlug.vue";
import AposInputString from "./@apostrophecms/schema/apos/components/AposInputString.vue";
import AposInputWrapper from "./@apostrophecms/schema/apos/components/AposInputWrapper.vue";
import AposLogo from "./@apostrophecms/schema/apos/components/AposLogo.vue";
import AposLogoIcon from "./@apostrophecms/schema/apos/components/AposLogoIcon.vue";
import AposLogoPadless from "./@apostrophecms/schema/apos/components/AposLogoPadless.vue";
import AposSchema from "./@apostrophecms/schema/apos/components/AposSchema.vue";
import AposSearchList from "./@apostrophecms/schema/apos/components/AposSearchList.vue";
import AposSubform from "./@apostrophecms/schema/apos/components/AposSubform.vue";
import TheAposBusy from "./@apostrophecms/busy/apos/components/TheAposBusy.vue";
import AposInputRole from "./@apostrophecms/permission/apos/components/AposInputRole.vue";
import AposInputAdminAllToggle from "./@apostrophecms-pro/advanced-permission/apos/components/AposInputAdminAllToggle.vue";
import AposInputNamedPermission from "./@apostrophecms-pro/advanced-permission/apos/components/AposInputNamedPermission.vue";
import AposInputPermissionsByGroup from "./@apostrophecms-pro/advanced-permission/apos/components/AposInputPermissionsByGroup.vue";
import AposInputPermissionsByType from "./@apostrophecms-pro/advanced-permission/apos/components/AposInputPermissionsByType.vue";
import AposInputUserList from "./@apostrophecms-pro/advanced-permission/apos/components/AposInputUserList.vue";
import AposPermissionGrid from "./@apostrophecms-pro/advanced-permission/apos/components/AposPermissionGrid.vue";
import TheAposAdminBar from "./@apostrophecms/admin-bar/apos/components/TheAposAdminBar.vue";
import TheAposAdminBarLocale from "./@apostrophecms/admin-bar/apos/components/TheAposAdminBarLocale.vue";
import TheAposAdminBarMenu from "./@apostrophecms/admin-bar/apos/components/TheAposAdminBarMenu.vue";
import TheAposAdminBarUser from "./@apostrophecms/admin-bar/apos/components/TheAposAdminBarUser.vue";
import TheAposContextBar from "./@apostrophecms/admin-bar/apos/components/TheAposContextBar.vue";
import TheAposContextBreakpointPreviewMode from "./@apostrophecms/admin-bar/apos/components/TheAposContextBreakpointPreviewMode.vue";
import TheAposContextModeAndSettings from "./@apostrophecms/admin-bar/apos/components/TheAposContextModeAndSettings.vue";
import TheAposContextTitle from "./@apostrophecms/admin-bar/apos/components/TheAposContextTitle.vue";
import TheAposContextUndoRedo from "./@apostrophecms/admin-bar/apos/components/TheAposContextUndoRedo.vue";
import TheAposSavingIndicator from "./@apostrophecms/admin-bar/apos/components/TheAposSavingIndicator.vue";
import CustomBar from "./@apostrophecms/admin-bar/apos/components/CustomBar.vue";
import AposNotification from "./@apostrophecms/notification/apos/components/AposNotification.vue";
import TheAposNotifications from "./@apostrophecms/notification/apos/components/TheAposNotifications.vue";
import AposForgotPasswordForm from "./@apostrophecms/login/apos/components/AposForgotPasswordForm.vue";
import AposLoginForm from "./@apostrophecms/login/apos/components/AposLoginForm.vue";
import AposResetPasswordForm from "./@apostrophecms/login/apos/components/AposResetPasswordForm.vue";
import TheAposLogin from "./@apostrophecms/login/apos/components/TheAposLogin.vue";
import TheAposLoginHeader from "./@apostrophecms/login/apos/components/TheAposLoginHeader.vue";
import AposRecaptcha from "./@apostrophecms/login-recaptcha/apos/components/AposRecaptcha.vue";
import AposTotp from "./@apostrophecms/login-totp/apos/components/AposTotp.vue";
import AposDocsManagerToolbar from "./@apostrophecms/modal/apos/components/AposDocsManagerToolbar.vue";
import AposModal from "./@apostrophecms/modal/apos/components/AposModal.vue";
import AposModalBody from "./@apostrophecms/modal/apos/components/AposModalBody.vue";
import AposModalBreadcrumbs from "./@apostrophecms/modal/apos/components/AposModalBreadcrumbs.vue";
import AposModalConfirm from "./@apostrophecms/modal/apos/components/AposModalConfirm.vue";
import AposModalLip from "./@apostrophecms/modal/apos/components/AposModalLip.vue";
import AposModalRail from "./@apostrophecms/modal/apos/components/AposModalRail.vue";
import AposModalReport from "./@apostrophecms/modal/apos/components/AposModalReport.vue";
import AposModalShareDraft from "./@apostrophecms/modal/apos/components/AposModalShareDraft.vue";
import AposModalTabs from "./@apostrophecms/modal/apos/components/AposModalTabs.vue";
import AposModalTabsBody from "./@apostrophecms/modal/apos/components/AposModalTabsBody.vue";
import AposModalToolbar from "./@apostrophecms/modal/apos/components/AposModalToolbar.vue";
import AposWidgetModalTabs from "./@apostrophecms/modal/apos/components/AposWidgetModalTabs.vue";
import TheAposModals from "./@apostrophecms/modal/apos/components/TheAposModals.vue";
import AposDocContextMenu from "./@apostrophecms/doc-type/apos/components/AposDocContextMenu.vue";
import AposDocEditor from "./@apostrophecms/doc-type/apos/components/AposDocEditor.vue";
import AposDocLocalePicker from "./@apostrophecms/doc-type/apos/components/AposDocLocalePicker.vue";
import AposDocsManager from "./@apostrophecms/piece-type/apos/components/AposDocsManager.vue";
import AposDocsManagerDisplay from "./@apostrophecms/piece-type/apos/components/AposDocsManagerDisplay.vue";
import AposDocsManagerSelectBox from "./@apostrophecms/piece-type/apos/components/AposDocsManagerSelectBox.vue";
import AposRelationshipEditor from "./@apostrophecms/piece-type/apos/components/AposRelationshipEditor.vue";
import AposUtilityOperations from "./@apostrophecms/piece-type/apos/components/AposUtilityOperations.vue";
import AposPagesManager from "./@apostrophecms/page/apos/components/AposPagesManager.vue";
import AposAreaContextualMenu from "./@apostrophecms/area/apos/components/AposAreaContextualMenu.vue";
import AposAreaEditor from "./@apostrophecms/area/apos/components/AposAreaEditor.vue";
import AposAreaExpandedMenu from "./@apostrophecms/area/apos/components/AposAreaExpandedMenu.vue";
import AposAreaMenu from "./@apostrophecms/area/apos/components/AposAreaMenu.vue";
import AposAreaMenuItem from "./@apostrophecms/area/apos/components/AposAreaMenuItem.vue";
import AposAreaWidget from "./@apostrophecms/area/apos/components/AposAreaWidget.vue";
import AposWidgetControls from "./@apostrophecms/area/apos/components/AposWidgetControls.vue";
import AposWidget from "./@apostrophecms/widget-type/apos/components/AposWidget.vue";
import AposWidgetEditor from "./@apostrophecms/widget-type/apos/components/AposWidgetEditor.vue";
import AposImageControlDialog from "./@apostrophecms/rich-text-widget/apos/components/AposImageControlDialog.vue";
import AposRichTextWidgetEditor from "./@apostrophecms/rich-text-widget/apos/components/AposRichTextWidgetEditor.vue";
import AposTiptapAnchor from "./@apostrophecms/rich-text-widget/apos/components/AposTiptapAnchor.vue";
import AposTiptapButton from "./@apostrophecms/rich-text-widget/apos/components/AposTiptapButton.vue";
import AposTiptapColor from "./@apostrophecms/rich-text-widget/apos/components/AposTiptapColor.vue";
import AposTiptapDivider from "./@apostrophecms/rich-text-widget/apos/components/AposTiptapDivider.vue";
import AposTiptapImage from "./@apostrophecms/rich-text-widget/apos/components/AposTiptapImage.vue";
import AposTiptapLink from "./@apostrophecms/rich-text-widget/apos/components/AposTiptapLink.vue";
import AposTiptapMarks from "./@apostrophecms/rich-text-widget/apos/components/AposTiptapMarks.vue";
import AposTiptapStyles from "./@apostrophecms/rich-text-widget/apos/components/AposTiptapStyles.vue";
import AposTiptapTable from "./@apostrophecms/rich-text-widget/apos/components/AposTiptapTable.vue";
import AposTiptapUndefined from "./@apostrophecms/rich-text-widget/apos/components/AposTiptapUndefined.vue";
import AposColor from "./@apostrophecms/color-field/apos/components/AposColor.vue";
import AposInputColor from "./@apostrophecms/color-field/apos/components/AposInputColor.vue";
import AposInputOembed from "./@apostrophecms/oembed-field/apos/components/AposInputOembed.vue";
import AposAvatar from "./@apostrophecms/ui/apos/components/AposAvatar.vue";
import AposButton from "./@apostrophecms/ui/apos/components/AposButton.vue";
import AposButtonGroup from "./@apostrophecms/ui/apos/components/AposButtonGroup.vue";
import AposButtonSplit from "./@apostrophecms/ui/apos/components/AposButtonSplit.vue";
import AposCellBasic from "./@apostrophecms/ui/apos/components/AposCellBasic.vue";
import AposCellButton from "./@apostrophecms/ui/apos/components/AposCellButton.vue";
import AposCellContextMenu from "./@apostrophecms/ui/apos/components/AposCellContextMenu.vue";
import AposCellDate from "./@apostrophecms/ui/apos/components/AposCellDate.vue";
import AposCellLabels from "./@apostrophecms/ui/apos/components/AposCellLabels.vue";
import AposCellLastEdited from "./@apostrophecms/ui/apos/components/AposCellLastEdited.vue";
import AposCellLink from "./@apostrophecms/ui/apos/components/AposCellLink.vue";
import AposCellType from "./@apostrophecms/ui/apos/components/AposCellType.vue";
import AposCheckbox from "./@apostrophecms/ui/apos/components/AposCheckbox.vue";
import AposCloudUploadIcon from "./@apostrophecms/ui/apos/components/AposCloudUploadIcon.vue";
import AposColorCheckerboard from "./@apostrophecms/ui/apos/components/AposColorCheckerboard.vue";
import AposCombo from "./@apostrophecms/ui/apos/components/AposCombo.vue";
import AposContextMenu from "./@apostrophecms/ui/apos/components/AposContextMenu.vue";
import AposContextMenuDialog from "./@apostrophecms/ui/apos/components/AposContextMenuDialog.vue";
import AposContextMenuItem from "./@apostrophecms/ui/apos/components/AposContextMenuItem.vue";
import AposContextMenuTip from "./@apostrophecms/ui/apos/components/AposContextMenuTip.vue";
import AposEmptyState from "./@apostrophecms/ui/apos/components/AposEmptyState.vue";
import AposFile from "./@apostrophecms/ui/apos/components/AposFile.vue";
import AposFilterMenu from "./@apostrophecms/ui/apos/components/AposFilterMenu.vue";
import AposIndicator from "./@apostrophecms/ui/apos/components/AposIndicator.vue";
import AposLabel from "./@apostrophecms/ui/apos/components/AposLabel.vue";
import AposLoading from "./@apostrophecms/ui/apos/components/AposLoading.vue";
import AposLoadingBlock from "./@apostrophecms/ui/apos/components/AposLoadingBlock.vue";
import AposLocale from "./@apostrophecms/ui/apos/components/AposLocale.vue";
import AposLocalePicker from "./@apostrophecms/ui/apos/components/AposLocalePicker.vue";
import AposMinMaxCount from "./@apostrophecms/ui/apos/components/AposMinMaxCount.vue";
import AposPager from "./@apostrophecms/ui/apos/components/AposPager.vue";
import AposPagerDots from "./@apostrophecms/ui/apos/components/AposPagerDots.vue";
import AposSelect from "./@apostrophecms/ui/apos/components/AposSelect.vue";
import AposSlat from "./@apostrophecms/ui/apos/components/AposSlat.vue";
import AposSlatList from "./@apostrophecms/ui/apos/components/AposSlatList.vue";
import AposSpinner from "./@apostrophecms/ui/apos/components/AposSpinner.vue";
import AposSubformPreview from "./@apostrophecms/ui/apos/components/AposSubformPreview.vue";
import AposTable from "./@apostrophecms/ui/apos/components/AposTable.vue";
import AposTag from "./@apostrophecms/ui/apos/components/AposTag.vue";
import AposTagApply from "./@apostrophecms/ui/apos/components/AposTagApply.vue";
import AposTagList from "./@apostrophecms/ui/apos/components/AposTagList.vue";
import AposTagListItem from "./@apostrophecms/ui/apos/components/AposTagListItem.vue";
import AposToggle from "./@apostrophecms/ui/apos/components/AposToggle.vue";
import AposTree from "./@apostrophecms/ui/apos/components/AposTree.vue";
import AposTreeHeader from "./@apostrophecms/ui/apos/components/AposTreeHeader.vue";
import AposTreeRows from "./@apostrophecms/ui/apos/components/AposTreeRows.vue";
import AposSettingsManager from "./@apostrophecms/settings/apos/components/AposSettingsManager.vue";
import AposImageCropper from "./@apostrophecms/image/apos/components/AposImageCropper.vue";
import AposImageRelationshipEditor from "./@apostrophecms/image/apos/components/AposImageRelationshipEditor.vue";
import AposMediaManager from "./@apostrophecms/image/apos/components/AposMediaManager.vue";
import AposMediaManagerDisplay from "./@apostrophecms/image/apos/components/AposMediaManagerDisplay.vue";
import AposMediaManagerEditor from "./@apostrophecms/image/apos/components/AposMediaManagerEditor.vue";
import AposMediaManagerSelections from "./@apostrophecms/image/apos/components/AposMediaManagerSelections.vue";
import AposMediaUploader from "./@apostrophecms/image/apos/components/AposMediaUploader.vue";
import AposSubmittedDraftIcon from "./@apostrophecms/submitted-draft/apos/components/AposSubmittedDraftIcon.vue";
import AposCommandMenuKey from "./@apostrophecms/command-menu/apos/components/AposCommandMenuKey.vue";
import AposCommandMenuKeyList from "./@apostrophecms/command-menu/apos/components/AposCommandMenuKeyList.vue";
import AposCommandMenuShortcut from "./@apostrophecms/command-menu/apos/components/AposCommandMenuShortcut.vue";
import TheAposCommandMenu from "./@apostrophecms/command-menu/apos/components/TheAposCommandMenu.vue";
import AposTranslationIndicator from "./@apostrophecms/translation/apos/components/AposTranslationIndicator.vue";
import AposDuplicateImportModal from "./@apostrophecms/import-export/apos/components/AposDuplicateImportModal.vue";
import AposExportModal from "./@apostrophecms/import-export/apos/components/AposExportModal.vue";
import AposImportModal from "./@apostrophecms/import-export/apos/components/AposImportModal.vue";
import AposSeoAssistantFieldMeta from "./@apostrophecms-pro/seo-assistant/apos/components/AposSeoAssistantFieldMeta.vue";
import AposSeoAssistantModal from "./@apostrophecms-pro/seo-assistant/apos/components/AposSeoAssistantModal.vue";
import SettingsDisplayNamePreview from "./asset/apos/components/SettingsDisplayNamePreview.vue";
import PrintFollowingField from "./household/apos/components/PrintFollowingField.vue";
import HouseholdWidgetPreview from "./household-widget/apos/components/HouseholdWidgetPreview.vue";
import TestMetaFieldIndicator from "./field-meta/apos/components/TestMetaFieldIndicator.vue";
import ColumnStarRating from "./star-rating-field/apos/components/ColumnStarRating.vue";
import InputStarRating from "./star-rating-field/apos/components/InputStarRating.vue";
import HeroWidgetEditor from "./hero-widget/apos/components/HeroWidgetEditor.vue";
import AposDataSetImport from "./@apostrophecms-pro/data-set/apos/components/AposDataSetImport.vue";
import AposDataSetManager from "./@apostrophecms-pro/data-set/apos/components/AposDataSetManager.vue";
import AposInputDataSet from "./@apostrophecms-pro/data-set/apos/components/AposInputDataSet.vue";
import AposInputDataSetColumns from "./@apostrophecms-pro/data-set/apos/components/AposInputDataSetColumns.vue";
import AposDocVersions from "./@apostrophecms-pro/document-versions/apos/components/AposDocVersions.vue";
import AposDocVersionsList from "./@apostrophecms-pro/document-versions/apos/components/AposDocVersionsList.vue";
import AposDocVersionsListCompare from "./@apostrophecms-pro/document-versions/apos/components/AposDocVersionsListCompare.vue";
import AposPaletteBody from "./@apostrophecms-pro/palette/apos/components/AposPaletteBody.vue";
import TheAposPalette from "./@apostrophecms-pro/palette/apos/components/TheAposPalette.vue";
import AposTemplateLibraryEditor from "./@apostrophecms-pro/doc-template-library/apos/components/AposTemplateLibraryEditor.vue";
import AposTemplateLibraryManager from "./@apostrophecms-pro/doc-template-library/apos/components/AposTemplateLibraryManager.vue";
import AposTemplateList from "./@apostrophecms-pro/doc-template-library/apos/components/AposTemplateList.vue";
import AposTemplateManagerDisplay from "./@apostrophecms-pro/doc-template-library/apos/components/AposTemplateManagerDisplay.vue";
import TheAposTemplateBar from "./@apostrophecms-pro/doc-template-library/apos/components/TheAposTemplateBar.vue";
import AposTranslationMeta from "./@apostrophecms-pro/automatic-translation/apos/components/AposTranslationMeta.vue";
import AposImportExportTranslationFile from "./@apostrophecms-pro/import-export-translation/apos/components/AposImportExportTranslationFile.vue";
import AposImportExportTranslationModal from "./@apostrophecms-pro/import-export-translation/apos/components/AposImportExportTranslationModal.vue";
import DemoCellRelation from "./article/apos/components/DemoCellRelation.vue";
import Anchor from "./@apostrophecms/rich-text-widget/apos/tiptap-extensions/Anchor.js";
import Classes from "./@apostrophecms/rich-text-widget/apos/tiptap-extensions/Classes.js";
import Color from "./@apostrophecms/rich-text-widget/apos/tiptap-extensions/Color.js";
import Default from "./@apostrophecms/rich-text-widget/apos/tiptap-extensions/Default.js";
import Div from "./@apostrophecms/rich-text-widget/apos/tiptap-extensions/Div.js";
import Document from "./@apostrophecms/rich-text-widget/apos/tiptap-extensions/Document.js";
import Heading from "./@apostrophecms/rich-text-widget/apos/tiptap-extensions/Heading.js";
import Image from "./@apostrophecms/rich-text-widget/apos/tiptap-extensions/Image.js";
import Link from "./@apostrophecms/rich-text-widget/apos/tiptap-extensions/Link.js";
import ListItem from "./@apostrophecms/rich-text-widget/apos/tiptap-extensions/ListItem.js";
import TextStyle from "./@apostrophecms/rich-text-widget/apos/tiptap-extensions/TextStyle.js";
import AposI18nBatchReporting_0App from "./@apostrophecms/i18n/apos/apps/AposI18nBatchReporting.js";
import AposI18nCrossDomainSession_1App from "./@apostrophecms/i18n/apos/apps/AposI18nCrossDomainSession.js";
import AposBusy_2App from "./@apostrophecms/busy/apos/apps/AposBusy.js";
import AposAdvancedPermissionStyle_3App from "./@apostrophecms-pro/advanced-permission/apos/apps/AposAdvancedPermissionStyle.js";
import AposAdminBar_4App from "./@apostrophecms/admin-bar/apos/apps/AposAdminBar.js";
import AposNotification_5App from "./@apostrophecms/notification/apos/apps/AposNotification.js";
import AposLogin_6App from "./@apostrophecms/login/apos/apps/AposLogin.js";
import AposDoc_7App from "./@apostrophecms/doc/apos/apps/AposDoc.js";
import AposModals_8App from "./@apostrophecms/modal/apos/apps/AposModals.js";
import AposAreas_9App from "./@apostrophecms/area/apos/apps/AposAreas.js";
import AposRichTextPermalinkResolver_10App from "./@apostrophecms/rich-text-widget/apos/apps/AposRichTextPermalinkResolver.js";
import AposBusEvent_11App from "./@apostrophecms/ui/apos/apps/AposBusEvent.js";
import TheAposSettings_12App from "./@apostrophecms/settings/apos/apps/TheAposSettings.js";
import AposImageRelationshipQueryFilter_13App from "./@apostrophecms/image/apos/apps/AposImageRelationshipQueryFilter.js";
import AposCommandMenu_14App from "./@apostrophecms/command-menu/apos/apps/AposCommandMenu.js";
import index_15App from "./@apostrophecms/import-export/apos/apps/index.js";
import AposPalette_16App from "./@apostrophecms-pro/palette/apos/apps/AposPalette.js";
import index_17App from "./@apostrophecms-pro/import-export-translation/apos/apps/index.js";
window.apos.iconComponents = window.apos.iconComponents || {};
window.apos.iconComponents['account-box-icon'] = AccountBoxIcon;
window.apos.iconComponents['alert-circle-icon'] = AlertCircleIcon;
window.apos.iconComponents['alpha-x-icon'] = AlphaXIcon;
window.apos.iconComponents['anchor-icon'] = AnchorIcon;
window.apos.iconComponents['apple-keyboard-caps'] = AppleKeyboardCapsIcon;
window.apos.iconComponents['apple-keyboard-command'] = AppleKeyboardCommandIcon;
window.apos.iconComponents['apple-keyboard-control'] = AppleKeyboardControlIcon;
window.apos.iconComponents['apple-keyboard-option'] = AppleKeyboardOptionIcon;
window.apos.iconComponents['apple-keyboard-shift'] = AppleKeyboardShiftIcon;
window.apos.iconComponents['archive-arrow-down-icon'] = ArchiveArrowDownIcon;
window.apos.iconComponents['archive-arrow-up-icon'] = ArchiveArrowUpIcon;
window.apos.iconComponents['arrow-collapse-vertical-icon'] = ArrowCollapseVerticalIcon;
window.apos.iconComponents['arrow-down-icon'] = ArrowDownIcon;
window.apos.iconComponents['arrow-expand-vertical-icon'] = ArrowExpandVerticalIcon;
window.apos.iconComponents['arrow-left-icon'] = ArrowLeftIcon;
window.apos.iconComponents['arrow-right-icon'] = ArrowRightIcon;
window.apos.iconComponents['arrow-up-icon'] = ArrowUpIcon;
window.apos.iconComponents['binoculars-icon'] = BinocularsIcon;
window.apos.iconComponents['calendar-icon'] = CalendarIcon;
window.apos.iconComponents['cellphone-icon'] = CellphoneIcon;
window.apos.iconComponents['check-all-icon'] = CheckAllIcon;
window.apos.iconComponents['check-bold-icon'] = CheckBoldIcon;
window.apos.iconComponents['check-circle-icon'] = CheckCircleIcon;
window.apos.iconComponents['check-decagram-icon'] = CheckDecagramIcon;
window.apos.iconComponents['checkbox-blank-icon'] = CheckboxBlankOutlineIcon;
window.apos.iconComponents['checkbox-marked-icon'] = CheckboxMarkedIcon;
window.apos.iconComponents['chevron-down-icon'] = ChevronDownIcon;
window.apos.iconComponents['chevron-left-icon'] = ChevronLeftIcon;
window.apos.iconComponents['chevron-right-icon'] = ChevronRightIcon;
window.apos.iconComponents['chevron-up-icon'] = ChevronUpIcon;
window.apos.iconComponents['circle-icon'] = CircleIcon;
window.apos.iconComponents['circle-medium-icon'] = CircleMediumIcon;
window.apos.iconComponents['clipboard-plus-icon'] = ClipboardPlusIcon;
window.apos.iconComponents['clipboard-plus-outline-icon'] = ClipboardPlusOutlineIcon;
window.apos.iconComponents['clock-icon'] = ClockIcon;
window.apos.iconComponents['close-circle-icon'] = CloseCircleIcon;
window.apos.iconComponents['close-icon'] = CloseIcon;
window.apos.iconComponents['cloud-upload-icon'] = CloudUploadIcon;
window.apos.iconComponents['code-tags-icon'] = CodeTagsIcon;
window.apos.iconComponents['cog-icon'] = CogIcon;
window.apos.iconComponents['content-copy-icon'] = ContentCopyIcon;
window.apos.iconComponents['content-cut-icon'] = ContentCutIcon;
window.apos.iconComponents['cursor-default-click-icon'] = CursorDefaultClickIcon;
window.apos.iconComponents['delete-icon'] = DeleteIcon;
window.apos.iconComponents['dots-vertical-icon'] = DotsVerticalIcon;
window.apos.iconComponents['drag-icon'] = AppsIcon;
window.apos.iconComponents['eye-icon'] = EyeIcon;
window.apos.iconComponents['file-document-icon'] = FileDocumentIcon;
window.apos.iconComponents['file-tree-icon'] = FileTreeIcon;
window.apos.iconComponents['flare-icon'] = FlareIcon;
window.apos.iconComponents['format-align-center-icon'] = FormatAlignCenterIcon;
window.apos.iconComponents['format-align-justify-icon'] = FormatAlignJustifyIcon;
window.apos.iconComponents['format-align-left-icon'] = FormatAlignLeftIcon;
window.apos.iconComponents['format-align-right-icon'] = FormatAlignRightIcon;
window.apos.iconComponents['format-bold-icon'] = FormatBoldIcon;
window.apos.iconComponents['format-font-icon'] = FormatFontIcon;
window.apos.iconComponents['format-italic-icon'] = FormatItalicIcon;
window.apos.iconComponents['format-list-bulleted-icon'] = FormatListBulletedIcon;
window.apos.iconComponents['format-list-numbered-icon'] = FormatListNumberedIcon;
window.apos.iconComponents['format-quote-close-icon'] = FormatQuoteCloseIcon;
window.apos.iconComponents['format-strikethrough-variant-icon'] = FormatStrikethroughVariantIcon;
window.apos.iconComponents['format-subscript-icon'] = FormatSubscriptIcon;
window.apos.iconComponents['format-superscript-icon'] = FormatSuperscriptIcon;
window.apos.iconComponents['format-text-icon'] = FormatTextIcon;
window.apos.iconComponents['format-underline-icon'] = FormatUnderlineIcon;
window.apos.iconComponents['help-circle-icon'] = HelpCircleIcon;
window.apos.iconComponents['image-edit-outline'] = ImageEditOutlineIcon;
window.apos.iconComponents['image-icon'] = ImageIcon;
window.apos.iconComponents['image-size-select-actual-icon'] = ImageSizeSelectActualIcon;
window.apos.iconComponents['information-icon'] = InformationIcon;
window.apos.iconComponents['information-outline-icon'] = InformationOutlineIcon;
window.apos.iconComponents['instagram-icon'] = InstagramIcon;
window.apos.iconComponents['keyboard-backspace'] = KeyboardBackspaceIcon;
window.apos.iconComponents['keyboard-esc'] = KeyboardEscIcon;
window.apos.iconComponents['keyboard-f1'] = KeyboardF1Icon;
window.apos.iconComponents['keyboard-f10'] = KeyboardF10Icon;
window.apos.iconComponents['keyboard-f11'] = KeyboardF11Icon;
window.apos.iconComponents['keyboard-f12'] = KeyboardF12Icon;
window.apos.iconComponents['keyboard-f2'] = KeyboardF2Icon;
window.apos.iconComponents['keyboard-f3'] = KeyboardF3Icon;
window.apos.iconComponents['keyboard-f4'] = KeyboardF4Icon;
window.apos.iconComponents['keyboard-f5'] = KeyboardF5Icon;
window.apos.iconComponents['keyboard-f6'] = KeyboardF6Icon;
window.apos.iconComponents['keyboard-f7'] = KeyboardF7Icon;
window.apos.iconComponents['keyboard-f8'] = KeyboardF8Icon;
window.apos.iconComponents['keyboard-f9'] = KeyboardF9Icon;
window.apos.iconComponents['keyboard-return'] = KeyboardReturnIcon;
window.apos.iconComponents['keyboard-space'] = KeyboardSpaceIcon;
window.apos.iconComponents['keyboard-tab'] = KeyboardTabIcon;
window.apos.iconComponents['label-icon'] = LabelIcon;
window.apos.iconComponents['lightbulb-on-icon'] = LightbulbOnIcon;
window.apos.iconComponents['link-icon'] = LinkIcon;
window.apos.iconComponents['list-status-icon'] = ListStatusIcon;
window.apos.iconComponents['lock-icon'] = LockIcon;
window.apos.iconComponents['magnify-icon'] = MagnifyIcon;
window.apos.iconComponents['map-marker-icon'] = MapMarkerIcon;
window.apos.iconComponents['menu-down-icon'] = MenuDownIcon;
window.apos.iconComponents['minus-box-icon'] = MinusBoxIcon;
window.apos.iconComponents['minus-icon'] = MinusIcon;
window.apos.iconComponents['monitor-icon'] = MonitorIcon;
window.apos.iconComponents['paperclip-icon'] = PaperclipIcon;
window.apos.iconComponents['pencil-icon'] = PencilIcon;
window.apos.iconComponents['phone-icon'] = PhoneIcon;
window.apos.iconComponents['play-box-icon'] = PlayBoxIcon;
window.apos.iconComponents['plus-icon'] = PlusIcon;
window.apos.iconComponents['redo-icon'] = RedoVariantIcon;
window.apos.iconComponents['refresh-icon'] = RefreshIcon;
window.apos.iconComponents['shape-icon'] = ShapeIcon;
window.apos.iconComponents['sign-text-icon'] = SignTextIcon;
window.apos.iconComponents['tablet-icon'] = TabletIcon;
window.apos.iconComponents['tag-icon'] = TagIcon;
window.apos.iconComponents['text-box-icon'] = TextBoxIcon;
window.apos.iconComponents['text-box-multiple-icon'] = TextBoxMultipleIcon;
window.apos.iconComponents['text-box-remove-icon'] = TextBoxRemoveIcon;
window.apos.iconComponents['trash-can-icon'] = TrashCanIcon;
window.apos.iconComponents['trash-can-outline-icon'] = TrashCanOutlineIcon;
window.apos.iconComponents['tune-icon'] = TuneIcon;
window.apos.iconComponents['undo-icon'] = UndoVariantIcon;
window.apos.iconComponents['unfold-less-horizontal-icon'] = UnfoldLessHorizontalIcon;
window.apos.iconComponents['unfold-more-horizontal-icon'] = UnfoldMoreHorizontalIcon;
window.apos.iconComponents['video-icon'] = VideoIcon;
window.apos.iconComponents['view-column-icon'] = ViewColumnIcon;
window.apos.iconComponents['water-off-icon'] = WaterOffIcon;
window.apos.iconComponents['web-icon'] = WebIcon;
window.apos.iconComponents['apos-import-export-download-icon'] = DownloadIcon;
window.apos.iconComponents['apos-import-export-upload-icon'] = UploadIcon;
window.apos.iconComponents['account-group-icon'] = AccountGroupIcon;
window.apos.iconComponents['database-export-icon'] = DatabaseExportIcon;
window.apos.iconComponents['account-icon'] = AccountIcon;
window.apos.iconComponents['format-color-highlight-icon'] = FormatColorHighlightIcon;
window.apos.iconComponents['table-icon'] = TableIcon;
window.apos.iconComponents['palette-swatch-icon'] = PaletteSwatchIcon;
window.apos.iconComponents['earth-icon'] = EarthIcon;
window.apos.iconComponents['database-check-icon'] = DatabaseCheckIcon;
window.apos.iconComponents['tray-full-icon'] = TrayFullIcon;
window.apos.iconComponents['database-import-icon'] = DatabaseImportIcon;
window.apos.iconComponents['form-select-icon'] = FormSelectIcon;
window.apos.iconComponents['form-textbox-icon'] = FormTextboxIcon;
window.apos.iconComponents['form-textarea-icon'] = FormTextareaIcon;
window.apos.iconComponents['radiobox-marked-icon'] = RadioboxMarkedIcon;
window.apos.iconComponents['checkbox-marked-outline-icon'] = CheckboxMarkedOutlineIcon;
window.apos.iconComponents['file-upload-outline-icon'] = FileUploadOutlineIcon;
window.apos.iconComponents['toggle-switch-outline-icon'] = ToggleSwitchOutlineIcon;
window.apos.iconComponents['arrow-decision-icon'] = ArrowDecisionIcon;
window.apos.iconComponents['creation-icon'] = CreationIcon;
window.apos.iconComponents['pillar'] = PillarIcon;
window.apos.iconComponents['file-table-box-icon'] = FileTableBoxIcon;
window.apos.iconComponents['view-split-vertical-icon'] = ViewSplitVerticalIcon;
window.apos.iconComponents['palette-icon'] = PaletteIcon;
window.apos.iconComponents['palette-advanced-icon'] = PaletteAdvancedIcon;
window.apos.iconComponents['translate-icon'] = TranslateIcon;
window.apos.iconComponents['download-icon'] = DownloadIcon;
window.apos.iconComponents['upload-icon'] = UploadIcon;
window.apos.iconComponents['text-icon'] = TextIcon;
window.apos.vueComponents = window.apos.vueComponents || {};
window.apos.vueComponents["AposI18nLocalize"] = AposI18nLocalize;
window.apos.vueComponents["AposArrayEditor"] = AposArrayEditor;
window.apos.vueComponents["AposInputArea"] = AposInputArea;
window.apos.vueComponents["AposInputArray"] = AposInputArray;
window.apos.vueComponents["AposInputAttachment"] = AposInputAttachment;
window.apos.vueComponents["AposInputBoolean"] = AposInputBoolean;
window.apos.vueComponents["AposInputCheckboxes"] = AposInputCheckboxes;
window.apos.vueComponents["AposInputDateAndTime"] = AposInputDateAndTime;
window.apos.vueComponents["AposInputObject"] = AposInputObject;
window.apos.vueComponents["AposInputPassword"] = AposInputPassword;
window.apos.vueComponents["AposInputRadio"] = AposInputRadio;
window.apos.vueComponents["AposInputRange"] = AposInputRange;
window.apos.vueComponents["AposInputRelationship"] = AposInputRelationship;
window.apos.vueComponents["AposInputSelect"] = AposInputSelect;
window.apos.vueComponents["AposInputSlug"] = AposInputSlug;
window.apos.vueComponents["AposInputString"] = AposInputString;
window.apos.vueComponents["AposInputWrapper"] = AposInputWrapper;
window.apos.vueComponents["AposLogo"] = AposLogo;
window.apos.vueComponents["AposLogoIcon"] = AposLogoIcon;
window.apos.vueComponents["AposLogoPadless"] = AposLogoPadless;
window.apos.vueComponents["AposSchema"] = AposSchema;
window.apos.vueComponents["AposSearchList"] = AposSearchList;
window.apos.vueComponents["AposSubform"] = AposSubform;
window.apos.vueComponents["TheAposBusy"] = TheAposBusy;
window.apos.vueComponents["AposInputRole"] = AposInputRole;
window.apos.vueComponents["AposInputAdminAllToggle"] = AposInputAdminAllToggle;
window.apos.vueComponents["AposInputNamedPermission"] = AposInputNamedPermission;
window.apos.vueComponents["AposInputPermissionsByGroup"] = AposInputPermissionsByGroup;
window.apos.vueComponents["AposInputPermissionsByType"] = AposInputPermissionsByType;
window.apos.vueComponents["AposInputUserList"] = AposInputUserList;
window.apos.vueComponents["AposPermissionGrid"] = AposPermissionGrid;
window.apos.vueComponents["TheAposAdminBar"] = TheAposAdminBar;
window.apos.vueComponents["TheAposAdminBarLocale"] = TheAposAdminBarLocale;
window.apos.vueComponents["TheAposAdminBarMenu"] = TheAposAdminBarMenu;
window.apos.vueComponents["TheAposAdminBarUser"] = TheAposAdminBarUser;
window.apos.vueComponents["TheAposContextBar"] = TheAposContextBar;
window.apos.vueComponents["TheAposContextBreakpointPreviewMode"] = TheAposContextBreakpointPreviewMode;
window.apos.vueComponents["TheAposContextModeAndSettings"] = TheAposContextModeAndSettings;
window.apos.vueComponents["TheAposContextTitle"] = TheAposContextTitle;
window.apos.vueComponents["TheAposContextUndoRedo"] = TheAposContextUndoRedo;
window.apos.vueComponents["TheAposSavingIndicator"] = TheAposSavingIndicator;
window.apos.vueComponents["CustomBar"] = CustomBar;
window.apos.vueComponents["AposNotification"] = AposNotification;
window.apos.vueComponents["TheAposNotifications"] = TheAposNotifications;
window.apos.vueComponents["AposForgotPasswordForm"] = AposForgotPasswordForm;
window.apos.vueComponents["AposLoginForm"] = AposLoginForm;
window.apos.vueComponents["AposResetPasswordForm"] = AposResetPasswordForm;
window.apos.vueComponents["TheAposLogin"] = TheAposLogin;
window.apos.vueComponents["TheAposLoginHeader"] = TheAposLoginHeader;
window.apos.vueComponents["AposRecaptcha"] = AposRecaptcha;
window.apos.vueComponents["AposTotp"] = AposTotp;
window.apos.vueComponents["AposDocsManagerToolbar"] = AposDocsManagerToolbar;
window.apos.vueComponents["AposModal"] = AposModal;
window.apos.vueComponents["AposModalBody"] = AposModalBody;
window.apos.vueComponents["AposModalBreadcrumbs"] = AposModalBreadcrumbs;
window.apos.vueComponents["AposModalConfirm"] = AposModalConfirm;
window.apos.vueComponents["AposModalLip"] = AposModalLip;
window.apos.vueComponents["AposModalRail"] = AposModalRail;
window.apos.vueComponents["AposModalReport"] = AposModalReport;
window.apos.vueComponents["AposModalShareDraft"] = AposModalShareDraft;
window.apos.vueComponents["AposModalTabs"] = AposModalTabs;
window.apos.vueComponents["AposModalTabsBody"] = AposModalTabsBody;
window.apos.vueComponents["AposModalToolbar"] = AposModalToolbar;
window.apos.vueComponents["AposWidgetModalTabs"] = AposWidgetModalTabs;
window.apos.vueComponents["TheAposModals"] = TheAposModals;
window.apos.vueComponents["AposDocContextMenu"] = AposDocContextMenu;
window.apos.vueComponents["AposDocEditor"] = AposDocEditor;
window.apos.vueComponents["AposDocLocalePicker"] = AposDocLocalePicker;
window.apos.vueComponents["AposDocsManager"] = AposDocsManager;
window.apos.vueComponents["AposDocsManagerDisplay"] = AposDocsManagerDisplay;
window.apos.vueComponents["AposDocsManagerSelectBox"] = AposDocsManagerSelectBox;
window.apos.vueComponents["AposRelationshipEditor"] = AposRelationshipEditor;
window.apos.vueComponents["AposUtilityOperations"] = AposUtilityOperations;
window.apos.vueComponents["AposPagesManager"] = AposPagesManager;
window.apos.vueComponents["AposAreaContextualMenu"] = AposAreaContextualMenu;
window.apos.vueComponents["AposAreaEditor"] = AposAreaEditor;
window.apos.vueComponents["AposAreaExpandedMenu"] = AposAreaExpandedMenu;
window.apos.vueComponents["AposAreaMenu"] = AposAreaMenu;
window.apos.vueComponents["AposAreaMenuItem"] = AposAreaMenuItem;
window.apos.vueComponents["AposAreaWidget"] = AposAreaWidget;
window.apos.vueComponents["AposWidgetControls"] = AposWidgetControls;
window.apos.vueComponents["AposWidget"] = AposWidget;
window.apos.vueComponents["AposWidgetEditor"] = AposWidgetEditor;
window.apos.vueComponents["AposImageControlDialog"] = AposImageControlDialog;
window.apos.vueComponents["AposRichTextWidgetEditor"] = AposRichTextWidgetEditor;
window.apos.vueComponents["AposTiptapAnchor"] = AposTiptapAnchor;
window.apos.vueComponents["AposTiptapButton"] = AposTiptapButton;
window.apos.vueComponents["AposTiptapColor"] = AposTiptapColor;
window.apos.vueComponents["AposTiptapDivider"] = AposTiptapDivider;
window.apos.vueComponents["AposTiptapImage"] = AposTiptapImage;
window.apos.vueComponents["AposTiptapLink"] = AposTiptapLink;
window.apos.vueComponents["AposTiptapMarks"] = AposTiptapMarks;
window.apos.vueComponents["AposTiptapStyles"] = AposTiptapStyles;
window.apos.vueComponents["AposTiptapTable"] = AposTiptapTable;
window.apos.vueComponents["AposTiptapUndefined"] = AposTiptapUndefined;
window.apos.vueComponents["AposColor"] = AposColor;
window.apos.vueComponents["AposInputColor"] = AposInputColor;
window.apos.vueComponents["AposInputOembed"] = AposInputOembed;
window.apos.vueComponents["AposAvatar"] = AposAvatar;
window.apos.vueComponents["AposButton"] = AposButton;
window.apos.vueComponents["AposButtonGroup"] = AposButtonGroup;
window.apos.vueComponents["AposButtonSplit"] = AposButtonSplit;
window.apos.vueComponents["AposCellBasic"] = AposCellBasic;
window.apos.vueComponents["AposCellButton"] = AposCellButton;
window.apos.vueComponents["AposCellContextMenu"] = AposCellContextMenu;
window.apos.vueComponents["AposCellDate"] = AposCellDate;
window.apos.vueComponents["AposCellLabels"] = AposCellLabels;
window.apos.vueComponents["AposCellLastEdited"] = AposCellLastEdited;
window.apos.vueComponents["AposCellLink"] = AposCellLink;
window.apos.vueComponents["AposCellType"] = AposCellType;
window.apos.vueComponents["AposCheckbox"] = AposCheckbox;
window.apos.vueComponents["AposCloudUploadIcon"] = AposCloudUploadIcon;
window.apos.vueComponents["AposColorCheckerboard"] = AposColorCheckerboard;
window.apos.vueComponents["AposCombo"] = AposCombo;
window.apos.vueComponents["AposContextMenu"] = AposContextMenu;
window.apos.vueComponents["AposContextMenuDialog"] = AposContextMenuDialog;
window.apos.vueComponents["AposContextMenuItem"] = AposContextMenuItem;
window.apos.vueComponents["AposContextMenuTip"] = AposContextMenuTip;
window.apos.vueComponents["AposEmptyState"] = AposEmptyState;
window.apos.vueComponents["AposFile"] = AposFile;
window.apos.vueComponents["AposFilterMenu"] = AposFilterMenu;
window.apos.vueComponents["AposIndicator"] = AposIndicator;
window.apos.vueComponents["AposLabel"] = AposLabel;
window.apos.vueComponents["AposLoading"] = AposLoading;
window.apos.vueComponents["AposLoadingBlock"] = AposLoadingBlock;
window.apos.vueComponents["AposLocale"] = AposLocale;
window.apos.vueComponents["AposLocalePicker"] = AposLocalePicker;
window.apos.vueComponents["AposMinMaxCount"] = AposMinMaxCount;
window.apos.vueComponents["AposPager"] = AposPager;
window.apos.vueComponents["AposPagerDots"] = AposPagerDots;
window.apos.vueComponents["AposSelect"] = AposSelect;
window.apos.vueComponents["AposSlat"] = AposSlat;
window.apos.vueComponents["AposSlatList"] = AposSlatList;
window.apos.vueComponents["AposSpinner"] = AposSpinner;
window.apos.vueComponents["AposSubformPreview"] = AposSubformPreview;
window.apos.vueComponents["AposTable"] = AposTable;
window.apos.vueComponents["AposTag"] = AposTag;
window.apos.vueComponents["AposTagApply"] = AposTagApply;
window.apos.vueComponents["AposTagList"] = AposTagList;
window.apos.vueComponents["AposTagListItem"] = AposTagListItem;
window.apos.vueComponents["AposToggle"] = AposToggle;
window.apos.vueComponents["AposTree"] = AposTree;
window.apos.vueComponents["AposTreeHeader"] = AposTreeHeader;
window.apos.vueComponents["AposTreeRows"] = AposTreeRows;
window.apos.vueComponents["AposSettingsManager"] = AposSettingsManager;
window.apos.vueComponents["AposImageCropper"] = AposImageCropper;
window.apos.vueComponents["AposImageRelationshipEditor"] = AposImageRelationshipEditor;
window.apos.vueComponents["AposMediaManager"] = AposMediaManager;
window.apos.vueComponents["AposMediaManagerDisplay"] = AposMediaManagerDisplay;
window.apos.vueComponents["AposMediaManagerEditor"] = AposMediaManagerEditor;
window.apos.vueComponents["AposMediaManagerSelections"] = AposMediaManagerSelections;
window.apos.vueComponents["AposMediaUploader"] = AposMediaUploader;
window.apos.vueComponents["AposSubmittedDraftIcon"] = AposSubmittedDraftIcon;
window.apos.vueComponents["AposCommandMenuKey"] = AposCommandMenuKey;
window.apos.vueComponents["AposCommandMenuKeyList"] = AposCommandMenuKeyList;
window.apos.vueComponents["AposCommandMenuShortcut"] = AposCommandMenuShortcut;
window.apos.vueComponents["TheAposCommandMenu"] = TheAposCommandMenu;
window.apos.vueComponents["AposTranslationIndicator"] = AposTranslationIndicator;
window.apos.vueComponents["AposDuplicateImportModal"] = AposDuplicateImportModal;
window.apos.vueComponents["AposExportModal"] = AposExportModal;
window.apos.vueComponents["AposImportModal"] = AposImportModal;
window.apos.vueComponents["AposSeoAssistantFieldMeta"] = AposSeoAssistantFieldMeta;
window.apos.vueComponents["AposSeoAssistantModal"] = AposSeoAssistantModal;
window.apos.vueComponents["SettingsDisplayNamePreview"] = SettingsDisplayNamePreview;
window.apos.vueComponents["PrintFollowingField"] = PrintFollowingField;
window.apos.vueComponents["HouseholdWidgetPreview"] = HouseholdWidgetPreview;
window.apos.vueComponents["TestMetaFieldIndicator"] = TestMetaFieldIndicator;
window.apos.vueComponents["ColumnStarRating"] = ColumnStarRating;
window.apos.vueComponents["InputStarRating"] = InputStarRating;
window.apos.vueComponents["HeroWidgetEditor"] = HeroWidgetEditor;
window.apos.vueComponents["AposDataSetImport"] = AposDataSetImport;
window.apos.vueComponents["AposDataSetManager"] = AposDataSetManager;
window.apos.vueComponents["AposInputDataSet"] = AposInputDataSet;
window.apos.vueComponents["AposInputDataSetColumns"] = AposInputDataSetColumns;
window.apos.vueComponents["AposDocVersions"] = AposDocVersions;
window.apos.vueComponents["AposDocVersionsList"] = AposDocVersionsList;
window.apos.vueComponents["AposDocVersionsListCompare"] = AposDocVersionsListCompare;
window.apos.vueComponents["AposPaletteBody"] = AposPaletteBody;
window.apos.vueComponents["TheAposPalette"] = TheAposPalette;
window.apos.vueComponents["AposTemplateLibraryEditor"] = AposTemplateLibraryEditor;
window.apos.vueComponents["AposTemplateLibraryManager"] = AposTemplateLibraryManager;
window.apos.vueComponents["AposTemplateList"] = AposTemplateList;
window.apos.vueComponents["AposTemplateManagerDisplay"] = AposTemplateManagerDisplay;
window.apos.vueComponents["TheAposTemplateBar"] = TheAposTemplateBar;
window.apos.vueComponents["AposTranslationMeta"] = AposTranslationMeta;
window.apos.vueComponents["AposImportExportTranslationFile"] = AposImportExportTranslationFile;
window.apos.vueComponents["AposImportExportTranslationModal"] = AposImportExportTranslationModal;
window.apos.vueComponents["DemoCellRelation"] = DemoCellRelation;
window.apos.tiptapExtensions = window.apos.tiptapExtensions || [];
apos.tiptapExtensions.push(Anchor);
apos.tiptapExtensions.push(Classes);
apos.tiptapExtensions.push(Color);
apos.tiptapExtensions.push(Default);
apos.tiptapExtensions.push(Div);
apos.tiptapExtensions.push(Document);
apos.tiptapExtensions.push(Heading);
apos.tiptapExtensions.push(Image);
apos.tiptapExtensions.push(Link);
apos.tiptapExtensions.push(ListItem);
apos.tiptapExtensions.push(TextStyle);
if (document.readyState !== 'loading') {
  setTimeout(invoke, 0);
} else {
  window.addEventListener('DOMContentLoaded', invoke);
}
function invoke() {
  AposI18nBatchReporting_0App();
  AposI18nCrossDomainSession_1App();
  AposBusy_2App();
  AposAdvancedPermissionStyle_3App();
  AposAdminBar_4App();
  AposNotification_5App();
  AposLogin_6App();
  AposDoc_7App();
  AposModals_8App();
  AposAreas_9App();
  AposRichTextPermalinkResolver_10App();
  AposBusEvent_11App();
  TheAposSettings_12App();
  AposImageRelationshipQueryFilter_13App();
  AposCommandMenu_14App();
  index_15App();
  AposPalette_16App();
  index_17App();
}
