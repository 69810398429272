<template>
  <div class="apos-input-permission__container">
    <label
      v-if="!readOnly && checked"
      class="apos-input-permission__toggle-all"
    >
      <span class="apos-input-permission__toggle-label">
        {{ $t('apostropheAdvancedPermission:toggleAll') }}
      </span>
      <AposCheckbox
        v-model="allToggled.value"
        tabindex="-1"
        :field="{
          name: 'toggleAll',
        }"
        :choice="{
          value: allToggled.value,
          indeterminate: allToggled.indeterminate,
          triggerIndeterminateEvent: true,
        }"
        @update:model-value="toggleAll"
      />
    </label>
    <table
      v-if="checked"
      class="apos-table"
      :class="{'apos-table--readonly': readOnly}"
    >
      <thead>
        <tr>
          <th class="apos-input-permission__table-header">
            {{ $t('apostropheAdvancedPermission:contentType') }}
          </th>
          <th
            v-for="{name, label} in availablePermissions"
            :key="name"
            class="apos-input-permission__table-header"
          >
            <div class="apos-input-permission__checkbox-container">
              <AposCheckbox
                v-if="!readOnly"
                v-model="cols[name].value"
                class="apos-input-checkbox__secondary"
                :data-apos-test="name"
                tabindex="-1"
                :field="{
                  name: name,
                  hideLabel: true,
                }"
                :choice="{
                  value: cols[name].value,
                  indeterminate: cols[name].indeterminate,
                  triggerIndeterminateEvent: true
                }"
                @update:model-value="(val) => toggleCol(val, name)"
              />

              <span class="apos-input-permission__table-header-name">
                {{ $t(label) }}
              </span>
            </div>
          </th>
          <th
            v-if="!readOnly"
            class="apos-input-permission__table-header apos-input-permission__toggle-cell"
          />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="{name, label, permissions} in permissionSets"
          :key="name"
          class="apos-input-permission__set"
        >
          <td class="apos-input-permission__table-cell apos-input-permission__set-name">
            {{ $t(label) }}
          </td>
          <td
            v-for="permission in permissions"
            :key="permission.name"
            class="apos-input-permission__table-cell"
          >
            <div class="apos-input-permission__checkbox-container">
              <AposCheckbox
                v-model="checked[name][permission.name]"
                v-apos-tooltip="getTooltip(name, permission, permissions)"
                tabindex="-1"
                :field="{
                  name: permission.name,
                  hideLabel: true,
                  readOnly: isCheckboxDisabled(name, permission, permissions)
                }"
                :choice="{
                  value: checked[name][permission.name]
                }"
                :data-apos-test="name"
                @update:model-value="(val) =>
                  change(name, permission.name, val, permissions)"
              />
            </div>
          </td>
          <td
            v-if="!readOnly"
            class="apos-input-permission__table-cell apos-input-permission__toggle-cell"
          >
            <div class="apos-input-permission__checkbox-container">
              <AposCheckbox
                v-model="rows[name].value"
                class="apos-input-checkbox__secondary"
                tabindex="-1"
                :field="{
                  name: name,
                  hideLabel: true,
                }"
                :choice="{
                  value: rows[name].value,
                  indeterminate: rows[name].indeterminate,
                  triggerIndeterminateEvent: true
                }"
                :data-apos-test="name"
                @update:model-value="(val) => toggleRow(val, name)"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'AposPermissionGrid',
  props: {
    icon: {
      type: String,
      default: 'menu-down-icon'
    },
    groupTitle: {
      type: String,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    apiParams: {
      type: Object,
      required: true,
      validator(value) {
        if (typeof value !== 'object') {
          return false;
        }

        const ADMIN = 'admin';
        const PERMISSIONS_BY_TYPE = 'permissionsByType';
        const GROUPS = '_groups';

        const keys = Object.keys(value);

        if (!keys.every(key => [ ADMIN, PERMISSIONS_BY_TYPE, GROUPS ].includes(key))) {
          return false;
        }
        if (Object.hasOwn(value, ADMIN) && typeof value[ADMIN] !== 'boolean') {
          return false;
        }
        if (value[PERMISSIONS_BY_TYPE] && !Array.isArray(value[PERMISSIONS_BY_TYPE])) {
          return false;
        }
        if (value[GROUPS] && !Array.isArray(value[GROUPS])) {
          return false;
        }
        return true;
      }
    }
  },
  emits: [ 'change' ],
  data() {
    return {
      permissionSets: [],
      contentTypes: apos.permission.contentTypes,
      checked: null,
      availablePermissions: [],
      requiredPermissions: {}
    };
  },
  computed: {
    allToggled() {
      const allPermissions = Object.values(this.checked).reduce((acc, permissions) => {
        return [
          ...acc,
          ...Object.values(permissions)
            .map((value) => value)
            .filter((value) => value != null)
        ];
      }, []);
      if (allPermissions.every((value) => value === false)) {
        return { value: false };
      }

      if (allPermissions.every((value) => value === true)) {
        return { value: true };
      }

      return {
        value: true,
        indeterminate: true
      };
    },
    rows() {
      return Object.entries(this.checked).reduce((rows, [ type, permissions ]) => {
        return {
          ...rows,
          [type]: this.getGlobalCheckboxValues(Object.values(permissions))
        };
      }, {});
    },
    cols() {
      return this.availablePermissions.reduce((acc, { name }) => {
        const permissions = Object.values(this.checked).map((type) => type[name]);
        return {
          ...acc,
          [name]: this.getGlobalCheckboxValues(permissions)
        };
      }, {});
    }
  },
  watch: {
    'apiParams.admin'(isAdmin) {
      if (isAdmin) {
        this.toggleAll(true);
      }
    },
    // Dynamic users readonly permission grid
    // updated when adding or removing _groups
    async 'apiParams._groups'() {
      await this.getPermissions();
      this.instantiateChecked();
    }
  },
  async mounted() {
    await this.getPermissions();
    this.instantiateChecked();
  },
  methods: {
    toggleAll(value) {
      this.checked = Object.entries(this.checked).reduce((acc, [ type, permissions ]) => {
        return {
          ...acc,
          [type]: this.updateObjectValues(permissions, value)
        };
      }, {});
      this.change();
    },
    toggleCol(value, updatedPerm) {
      this.checked = Object.entries(this.checked).reduce((acc, [ type, permissions ]) => {
        const isAutopublish = this.isAutopublishedType(type);
        const row = {
          ...permissions,
          [updatedPerm]: permissions[updatedPerm] == null ? null : value
        };

        if (isAutopublish && [ 'edit', 'publish' ].includes(updatedPerm)) {
          row.publish = updatedPerm === 'edit'
            ? value
            : this.checked[type].publish;
        }

        const { permissions: rowPermissions = [] } = this.permissionSets
          .find(({ name }) => name === type);
        return {
          ...acc,
          [type]: this.computeRequiredPermRow(row, rowPermissions)
        };
      }, {});
      this.change();
    },
    toggleRow(value, updatedType) {
      this.checked = Object.entries(this.checked).reduce((acc, [ type, permissions ]) => {
        return {
          ...acc,
          [type]: updatedType === type
            ? this.updateObjectValues(permissions, value)
            : permissions
        };
      }, {});
      this.change();
    },
    updateObjectValues(obj, newValue) {
      return Object.fromEntries(
        Object.entries(obj).map(([ prop ]) => [
          prop,
          obj[prop] == null ? null : newValue
        ])
      );
    },
    getGlobalCheckboxValues(permissions) {
      if (permissions.every((p) => p === true)) {
        return {
          value: true
        };
      }

      if (permissions.every((p) => !p)) {
        return {
          value: false
        };
      }

      return {
        value: true,
        indeterminate: true
      };
    },
    async getPermissions() {
      const {
        permissionSets, permissions
      } = await apos.http.post(`${apos.permission.action}/grid`, {
        body: this.apiParams,
        busy: true
      });

      this.permissionSets = permissionSets;
      this.availablePermissions = permissions;
    },
    instantiateChecked() {
      this.checked = this.permissionSets.reduce((acc, { name: type, permissions }) => {
        return {
          ...acc,
          [type]: Object.fromEntries(
            permissions.map(({ name, value }) => {
              return [
                name,
                this.getInstantiationValue(value, type, name, permissions)
              ];
            })
          )
        };
      }, {});

    },
    getInstantiationValue(val, type, name, permissions) {
      return this.isAutopublishedType(type) && name === 'publish'
        ? permissions.find(({ name }) => name === 'edit').value
        : val;
    },
    change(type, permName, val, permissions) {
      if (type && permName) {
        this.computeAutopublishMirror(type, permName, val);
        this.checked[type] = this.computeRequiredPermRow(this.checked[type], permissions);
      }

      this.$emit('change', this.checked);
    },
    computeAutopublishMirror(type, permName, val) {
      if (!this.isAutopublishedType(type) || permName !== 'edit') {
        return;
      }

      this.checked[type].publish = val;
    },
    computeRequiredPermRow(row, permissions) {
      return Object.entries(row)
        .reduce((acc, [ name, value ]) => {
          const requires = permissions.find((perm) => perm.name === name)?.requires;

          return {
            ...acc,
            [name]: requires && value ? row[requires] : value
          };
        }, {});
    },
    isAutopublishedType(type) {
      return apos.modules[type].autopublish || apos.modules[type].localized === false;
    },
    isCheckboxDisabled(type, { name, value }, permissions) {
      if (name === 'publish' && !this.readOnly && this.isAutopublishedType(type)) {
        return true;
      }

      const requires = permissions.find((perm) => perm.name === name)?.requires;
      if (requires) {
        if (typeof requires === 'object') {
          if (requires.$or) {
            return !requires.$or.some(r =>
              Object.entries(r)
                .every(([ key, value ]) => this.checked[type][key] === value)
            );
          }
          return !Object.entries(requires)
            .every(([ key, value ]) => this.checked[type][key] === value);
        }

        return !this.checked[type][requires];
      }

      return value == null || this.readOnly;
    },
    getTooltip(type, { name, value }, permissions) {
      if (this.readOnly) {
        return typeof this.groupTitle !== 'string'
          ? false
          : this.buildTooltipHtml(
            this.$t('apostropheAdvancedPermission:readOnlyTooltipTitle', {
              group: this.groupTitle
            }),
            this.$t('apostropheAdvancedPermission:readOnlyTooltipMsg')
          );
      }

      const isAutopublish = this.isAutopublishedType(type);
      if (name === 'publish' && isAutopublish) {
        const label = this.permissionSets.find(({ name }) => name === type)?.label ||
          this.$t('apostropheAdvancedPermission:theseDocuments');

        return this.buildTooltipHtml(
          this.$t('apostropheAdvancedPermission:autopublishPermission', {
            type: this.$t(label),
            option: this.$t(`apostropheAdvancedPermission:${
              apos.modules[type].autopublish ? 'autopublished' : 'notLocalized'
            }`)
          }),
          this.$t('apostropheAdvancedPermission:autopublishPermissionMsg')
        );
      }

      if (value === null) {
        return false;
      }

      const requires = permissions.find((perm) => perm.name)?.requires;
      const requiredLabel = requires &&
        this.availablePermissions.find((perm) => perm.name === requires)?.label;
      if (requires && this.checked[type][requires] !== true) {
        return this.buildTooltipHtml(
          this.$t('apostropheAdvancedPermission:requiresAnotherPermission'),
          this.$t(
            'apostropheAdvancedPermission:requiresAnotherPermissionMsg',
            { requiredPerm: this.$t(requiredLabel).toLowerCase() }
          )
        );
      }

      return false;
    },
    buildTooltipHtml(title, msg) {
      return `<p><strong>${title}</strong></p><p>${msg}</p>`;
    }
  }
};
</script>

<style lang="scss" scoped>
  .apos-table {
    @include type-base;
  }

  .apos-table--readonly {
    .apos-input-permission__table-cell .apos-input-permission__checkbox-container {
      :deep(input[type="checkbox"][value="true"] + .apos-input-indicator) {
        background-color: var(--a-primary-light-40);
        border-color: var(--a-primary-light-40);
      }
    }

    .apos-input-permission__table-header-name {
      margin: 0;
    }
  }

  :deep(
    .apos-choice-label--disabled input[type="checkbox"][value="true"] +
    .apos-input-indicator
  ) {
    background-color: var(--a-primary-light-40);
    border-color: var(--a-primary-light-40);
  }

  .apos-input-permission__container {
    position: relative;
  }

  .apos-input-permission__toggle-all {
    position: absolute;
    top: -27px;
    right: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .apos-input-permission__toggle-label {
    @include type-label;

    & {
      margin-right: 5px;
    }
  }

  .apos-input-permission__table-header {
    @include type-base;

    & {
      padding: $spacing-base;
      border: 1px solid var(--a-base-8);
      text-align: left;
      background-color: var(--a-base-9);
    }
  }

  .apos-input-permission__table-header:last-child {
    background-color: var(--a-primary-transparent-10);
  }

  .apos-input-permission__table-header:not(:first-child) {
    text-align: center
  }

  .apos-input-permission__table-cell {
    height: 100%;
    border: 1px solid var(--a-base-8);
    border-top: none;
  }

  .apos-input-permission__toggle-cell {
    background-color: var(--a-primary-transparent-10);
    padding: 0 7px;
  }

  .apos-input-permission__set {
    height: 40px
  }

  .apos-input-permission__set-name {
    padding-left: $spacing-base + $spacing-half;
  }

  .apos-input-permission__checkbox-container {
    display: flex;
    gap: $spacing-half;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .apos-input-checkbox__secondary {
    :deep(.apos-input--choice:checked + .apos-input-indicator),
    :deep(.apos-input--choice[checked] + .apos-input-indicator) {
      border-color: var(--a-base-4);
      background-color: var(--a-base-10);
      color: var(--a-black);
    }

    :deep(.apos-input--choice:focus ~ .apos-input-indicator) {
      outline: none;
      box-shadow: 0 0 5px var(--a-base-1);
    }
  }
</style>
