<script>
import AposInputArray from 'Modules/@apostrophecms/schema/components/AposInputArray.vue';

export default {
  components: {
    AposInputArray
  },
  extends: AposInputArray,
  data() {
    return {
      dataSchema: this.modelValue?.data?.schema || []
    };
  },
  computed: {
    schema() {
      return this.dataSchema;
    }
  },
  watch: {
    field() {
      this.field.titleField = this.field.titleField || this.dataSchema[0]?.name;
    }
  },
  methods: {
    getNext() {
      // For document versions, during the first pass this.modelValue.data has no schema
      if (
        this.field.schema.length === 0 &&
        Array.isArray(this.modelValue?.data?.schema)
      ) {
        this.dataSchema = this.modelValue.data.schema;
        return this.modelValue.data.data;
      }

      return Array.isArray(this.modelValue?.data)
        ? this.modelValue.data
        : (this.field.def || []);
    }
  }
};
</script>

<style
  lang="scss"
  src="apostrophe/modules/@apostrophecms/schema/ui/apos/scss/AposInputArray.scss"
  scoped
>
</style>
