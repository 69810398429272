<template>
  <div>
    <AposInputWrapper
      :field="field"
      :error="effectiveError"
      :uid="uid"
      :modifiers="modifiers"
      :display-options="displayOptions"
    >
      <template #body>
        <div class="apos-field__container">
          <div class="apos-field__info">
            <p>{{ $t('apostropheAdvancedPermission:adminFieldHelp') }}</p>
            <ul>
              <li>{{ $t('apostropheAdvancedPermission:adminFieldHelpUser') }}</li>
              <li>{{ $t('apostropheAdvancedPermission:adminFieldHelpGroup') }}</li>
              <li v-if="adminModuleLabel">
                {{
                  $t('apostropheAdvancedPermission:adminFieldHelpOther', {
                    label: adminModuleLabel
                  })
                }}
              </li>
            </ul>
          </div>
          <AposToggle
            v-model="disabled"
            class="apos-field__toggle"
            @toggle="toggle"
          />
        </div>
      </template>
    </AposInputWrapper>
  </div>
</template>

<script>
import AposInputMixin from 'Modules/@apostrophecms/schema/mixins/AposInputMixin';

export default {
  name: 'AposInputAdminAllToggle',
  mixins: [ AposInputMixin ],
  data() {
    const next = (this.modelValue && this.modelValue.data) || false;

    return {
      next,
      disabled: !next,
      adminModuleLabel: apos.modules['@apostrophecms/permission']
        .adminModuleLabel?.toLowerCase()
    };
  },
  methods: {
    toggle() {
      this.disabled = !this.disabled;
      this.next = !this.disabled;
    },
    validate(value) {
      if (this.field.required && !value) {
        return 'required';
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>

  .apos-field__wrapper :deep(.apos-field__label) {
    margin-bottom: 0;
  }

  .apos-field__container {
    display: flex;
  }

  .apos-field__info {
    @include type-label;

    & {
      font-weight: 400;
      letter-spacing: 0.15px;
      line-height: var(--a-line-tallest);
    }
  }

  .apos-field__info p {
    margin: 10px 0 8px;
  }

  .apos-field__info ul {
    margin-top: 8px;
    padding-left: 10px;
  }

  .apos-field__toggle {
    margin-top: 10px;
    margin-left: 60px;
  }
</style>
