<template>
  <div class="widget-root">
    <h1>{{ moduleOptions.label }}</h1>
    <div
      class="widget-data"
      data-apos-test="widget-following"
    >
      <h2 v-if="grandParentTitle">
        Grand Parent: {{ grandParentTitle }}
      </h2>
      <h3 v-if="parentTitle">
        Parent: {{ parentTitle }}
      </h3>
      <h4>Current: {{ modelValue.title }}</h4>
      <h5 v-if="nestedTitle">
        Child: {{ nestedTitle }}
      </h5>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    docId: {
      type: String,
      default: null
    },
    type: {
      type: String,
      required: true
    },
    areaFieldId: {
      type: String,
      required: true
    },
    modelValue: {
      type: Object,
      default() {
        return {};
      }
    },
    areaField: {
      type: Object,
      default() {
        return {};
      }
    },
    followingValues: {
      type: Object,
      default() {
        return {};
      }
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    moduleOptions() {
      return apos.modules[`${this.type}-widget`];
    },
    grandParentTitle() {
      return this.followingValues['<<title'];
    },
    parentTitle() {
      return this.followingValues['<title'];
    },
    nestedTitle() {
      return this.modelValue.nested?.items?.[0]?.title;
    }
  }
};
</script>

<style lang="scss" scoped>
.widget-root {
  margin: 20px 0;
}
.widget-data {
  margin-left: 30px;
}
</style>
