<template>
  <AposInputWrapper
    class="apos-attachment-wrapper"
    :field="{
      label: 'importExportTranslation:importManagerFieldFileLabel',
      name: 'jsonFile'
    }"
    :uid="0"
  >
    <template #body>
      <div class="apos-attachment">
        <AposFile
          :allowed-extensions="'.json'"
          :uploading="busy"
          :disabled="busy"
          :attachment="model.jsonFile"
          @upload-file="selectFile"
          @update="update"
        />
      </div>
    </template>
  </AposInputWrapper>
</template>

<script>
export default {
  name: 'AposImportExportTranslationFile',
  props: {
    busy: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Object,
      default: () => null
    }
  },
  emits: [ 'update:modelValue' ],
  data() {
    return {
      model: { jsonFile: null }
    };
  },
  computed: {

  },
  async mounted() {
  },
  methods: {
    selectFile(file) {
      this.model.jsonFile = file;
      this.$emit('update:modelValue', this.model.jsonFile);
    },
    update(event) {
      this.model.jsonFile = event[0] || null;
      this.$emit('update:modelValue', this.model.jsonFile);
    }
  }
};
</script>

<style lang="scss" scoped>
.apos-attachment{
  width: 100%;
}

.apos-attachment-wrapper {
  width: 100%;
}
</style>
