<template>
  <div :class="{ focused }">
    <input
      class="contextual-heading"
      type="text"
      v-model="heading"
      placeholder="Heading"
      :style="style"
    />
    <div class="contextual-heading-controls">
      <button
        :class="buttonClasses(25)"
        @click.stop.prevent="setWidth(25)"
      >25%</button>
      <button
        :class="buttonClasses(50)"
        @click.stop.prevent="setWidth(50)"
      >50%</button>
      <button
        :class="buttonClasses(75)"
        @click.stop.prevent="setWidth(75)"
      >75%</button>
      <button
        :class="buttonClasses(100)"
        @click.stop.prevent="setWidth(100)"
      >100%</button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HeroWidgetEditor',
  props: {
    type: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    value: {
      type: Object,
      default() {
        return {};
      }
    },
    docId: {
      type: String,
      required: false,
      default() {
        return null;
      }
    },
    focused: {
      type: Boolean,
      default: false
    }
  },
  emits: [ 'update' ],
  data() {
    return {
      heading: this.value.heading,
      width: this.value.width
    };
  },
  watch: {
    heading() {
      this.emitUpdate();
    },
    width() {
      this.emitUpdate();
    }
  },
  computed: {
    style() {
      return {
        width: `${this.width}%`
      };
    }
  },
  methods: {
    emitUpdate() {
      this.$emit('update', {
        ...this.value,
        heading: this.heading,
        width: this.width
      });
    },
    setWidth(n) {
      this.width = n;
    },
    buttonClasses(width) {
      return {
        [`width-${width}`]: true,
        active: this.width === width
      };
    }
  }
};

</script>

<style lang="scss" scoped>
  .contextual-heading-controls {
    display: none;
    text-align: center;
  }
  .focused .contextual-heading-controls {
    display: block;
  }
  .contextual-heading-controls button {
    font-size: 10px;
  }
  button.active {
    background-color: red;
    color: white;
  }
  .width-25 {
    width: 36px;
    height: 24px;
  }
  .width-50 {
    width: 72px;
    height: 24px;
  }
  .width-75 {
    width: 96px;
    height: 24px;
  }
  .width-100 {
    width: 144px;
    height: 24px;
  }
</style>
