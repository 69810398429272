<template>
  <AposInputWrapper
    :modifiers="modifiers"
    :field="field"
    :error="effectiveError"
    :uid="uid"
    :display-options="displayOptions"
  >
    <template #body>
      <div class="apos-input-wrapper">
        <div
          class="print"
          :class="classes"
        >
          <span
            v-if="printFollowingValue"
            data-apos-test="value"
          >{{ printFollowingValue }}</span>
          <span v-else>&nbsp;</span>
        </div>
      </div>
    </template>
  </AposInputWrapper>
</template>

<script>
// A dummy component that only prints the values of the following fields.
import AposInputMixin from 'Modules/@apostrophecms/schema/mixins/AposInputMixin';

export default {
  name: 'PrintFollowingField',
  mixins: [ AposInputMixin ],
  emits: [ 'return' ],
  data () {
    return {
      wasPopulated: true
    };
  },
  computed: {
    classes () {
      return [ 'apos-input', `apos-input--${this.type}` ];
    },
    printFollowingValue () {
      return Object.values(this.followingValues || []).join(' ').trim();
    }
  },
  methods: {
    validate() {
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
  .print {
    padding: 15px;
  }
</style>
