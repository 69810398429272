import createApp from 'Modules/@apostrophecms/ui/lib/vue';

export default function() {
  const component = apos.vueComponents.TheAposPalette;
  const isLoggedIn = !!apos.login.user;
  const el = document.querySelector('#apos-palette');
  if (el && window.apos.modules['@apostrophecms-pro/palette'] && isLoggedIn) {
    const app = createApp(component);
    app.mount(el);
  }
}
