<template>
  <AposInputWrapper
    :field="field"
    :uid="uid"
    :modifiers="['full-width']"
  >
    <template #body>
      <AposPermissionGrid
        :api-params="{ _groups: groups }"
        :read-only="true"
      />
    </template>
  </AposInputWrapper>
</template>

<script>
import AposInputMixin from 'Modules/@apostrophecms/schema/mixins/AposInputMixin';

export default {
  name: 'AposInputRolesByGroup',
  mixins: [ AposInputMixin ],
  computed: {
    groups() {
      return this.followingValues && Array.isArray(this.followingValues._groups)
        ? this.followingValues._groups.map(({ admin, permissionsByType }) => ({
          admin,
          permissionsByType
        }))
        : [];
    }
  },
  methods: {
    // Override these methods because we don't deal with
    // `this.value` or `this.next`, but with `this.followingValues`
    validateAndEmit() {},
    watchValue() {},
    watchNext() {}
  }
};
</script>
