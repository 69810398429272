<template>
  <div class="seo-modal">
    <label
      v-if="label"
      class="seo-modal__label"
    >
      {{ label }}
    </label>
    <span
      v-if="showError"
      :key="error"
      class="seo-modal__error"
    >{{ error }}</span>
    <div class="seo-modal__wrapper">
      <Transition
        name="fade"
        mode="out-in"
      >
        <!-- Show suggestion -->
        <div
          v-if="state === 'suggestion'"
          class="seo-modal__suggestion"
          data-apos-test="state-suggestion"
        >
          {{ suggestion }}
        </div>
        <!-- Loading suggestion -->
        <div
          v-else-if="state === 'loading'"
          class="seo-modal__loading"
          data-apos-test="state-loading"
        >
          <AposSpinner
            color="--a-brand-green"
            color-light="#8EFF92"
            weight="heavy"
          />
        </div>
        <!-- Edit prompt -->
        <div
          v-else-if="state === 'edit'"
          class="seo-modal__edit"
          data-apos-test="state-edit"
        >
          <AposInputString
            v-model="prompt"
            :field="areaEditorField"
            :rows="10"
          />
        </div>
      </Transition>
    </div>
    <div class="apos-modal__footer">
      <AposButton
        v-for="tab in tabs"
        v-show="tab.states.includes(state)"
        :key="tab.name"
        :action="tab.action"
        :label="$t(tab.label)"
        type="subtle"
        :modifiers="[ 'small' ]"
        :disabled="state === 'loading'"
        @click="execute(tab.action)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AposSeoAssistantModal',
  inject: [ 'liveOriginalDoc' ],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    field: {
      type: Object,
      required: true
    },
    suggestionText: {
      type: String,
      default: ''
    },
    promptText: {
      type: String,
      default: ''
    }
  },
  emits: [ 'apply-suggestion', 'suggestion', 'prompt' ],
  data() {
    return {
      suggestion: this.suggestionText,
      defaultPrompt: '',
      customPrompt: this.promptText,
      states: [ 'suggestion', 'loading', 'edit' ],
      tabs: [
        {
          name: 'useSuggestion',
          label: 'aposSeoAssistant:tabUseSuggestion',
          states: [ 'suggestion', 'loading' ],
          action: 'apply'
        },
        {
          name: 'retrySuggestion',
          label: 'aposSeoAssistant:tabTryAgain',
          states: [ 'suggestion', 'loading' ],
          action: 'retry'
        },
        {
          name: 'editPrompt',
          label: 'aposSeoAssistant:tabEditPrompt',
          states: [ 'suggestion', 'loading' ],
          action: 'edit'
        },
        {
          name: 'editCancel',
          label: 'apostrophe:cancel',
          states: [ 'edit' ],
          action: 'editCancel'
        },
        {
          name: 'editSubmit',
          label: 'aposSeoAssistant:tabGetSuggestion',
          states: [ 'edit' ],
          action: 'editSubmit'
        }
      ],
      state: 'suggestion',
      error: '',
      areaEditorField: {
        _id: this.field._id + 'areaEditor',
        type: 'string',
        textarea: true
      },
      mounted: false
    };
  },
  computed: {
    label() {
      if (this.state === 'edit') {
        return this.$t('aposSeoAssistant:labelReprompt');
      }
      return this.$t(this.field.label);
    },
    prompt: {
      get() {
        return {
          data: this.customPrompt || this.defaultPrompt
        };
      },
      set(next) {
        this.customPrompt = next.data ?? '';
      }
    },
    route() {
      return apos.modules['@apostrophecms-pro/seo-assistant']?.action;
    },
    showError() {
      return this.error && this.state === 'suggestion';
    }
  },
  watch: {
    visible(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.onBecomeVisible();
      }
    }
  },
  async mounted() {
    this.mounted = true;
    await this.loadDefaultPrompt();
  },
  unmounted() {
    this.mounted = false;
  },
  methods: {
    async onBecomeVisible() {
      if (!this.suggestion || this.error) {
        await this.execute('getSuggestion');
      }
    },
    async execute(action) {
      switch (action) {
        case 'apply':
          this.$emit('apply-suggestion', {
            text: this.suggestion,
            prompt: this.customPrompt,
            field: this.field
          });
          break;

        case 'edit':
          this.state = 'edit';
          break;

        case 'editCancel':
          this.state = 'suggestion';
          break;

        case 'retry':
        case 'editSubmit':
        case 'getSuggestion': {
          this.error = '';
          this.state = 'loading';
          await this.getSuggestion();
          this.state = 'suggestion';
          this.$emit('suggestion', this.suggestion);
          this.$emit('prompt', this.customPrompt);
          break;
        }

        default:
          console.error('Unknown action', action);
          this.error = 'Unknown action';
          this.state = 'suggestion';
          break;
      }
    },
    async loadDefaultPrompt() {
      if (this.defaultPrompt) {
        return;
      }
      try {
        const result = await apos.http.get(`${this.route}/prompt`, {
          qs: {
            fieldId: this.field._id,
            docId: this.liveOriginalDoc?._id
          }
        });
        if (!this.mounted) {
          return;
        }
        this.defaultPrompt = result;
      } catch (e) {
        console.error('Failed to load editor prompt', e);
        const clientMessage = this.$t('aposSeoAssistant:errorPromptRequest');
        const serverMessage = this.getServerMessage(e);
        await apos.notify(`${clientMessage} ${serverMessage}`, {
          type: 'warning',
          dismiss: true
        });
      }
    },
    async getSuggestion() {
      try {
        const result = await apos.http.post(`${this.route}/suggestion`, {
          qs: {
            fieldId: this.field._id
          },
          body: {
            doc: this.liveOriginalDoc?.data,
            prompt: this.customPrompt
          }
        });
        if (!this.mounted) {
          return;
        }
        this.suggestion = result || '';
        if (!this.suggestion.trim().length) {
          this.error = this.$t('aposSeoAssistant:errorNoSuggestion');
        }
      } catch (e) {
        console.error('Failed to get suggestion', e);
        this.setServerError(e);
      }
    },
    getServerMessage(error) {
      return error.body?.data?.message ?? '';
    },
    setServerError(error) {
      this.error = this.getServerMessage(error) || error.body?.message || error.name;
    }
  }
};
</script>

<style lang="scss" scoped>
.seo-modal {
  // The color used by the translation core svg icon.
  --green-bg: #{color.adjust(#8EFF92, $alpha: -0.5)};

  width: 500px;
  padding: 20px 20px 10px;

  &__label {
    @include type-base;

    & {
      display: block;
      margin-bottom: 5px;
      line-height: var(--a-line-tall);
      color: var(--a-base-3);
    }
  }

  &__error {
    @include type-help;

    & {
      display: block;
      margin: $spacing-base 0;
      color: var(--a-danger);
    }
  }
}

.seo-modal__wrapper {
  background-color: var(--green-bg);
}

.seo-modal__wrapper,
.seo-modal__suggestion,
.seo-modal__loading,
.seo-modal__edit {
  min-height: 100px;
}

.seo-modal__suggestion,
.seo-modal__loading {
  @include type-large;

  & {
    padding: 10px;
  }
}

.seo-modal__edit {
  background-color: var(--a-white);
}

.seo-modal__loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.apos-modal__footer {
  display: flex;
  gap: 10px;
  margin-top: 10px;

  :deep(.apos-button) {
    padding: 7.5px 10px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 200ms ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
