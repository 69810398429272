<template>
  <AposContextMenu
    v-if="isVisible"
    ref="menu"
    :unpadded="true"
    menu-placement="bottom-start"
    :menu-offset="12"
    :button="menuButton"
    data-apos-test="seo-assistant-context-menu"
    @close="isModalVisible = false"
  >
    <AposSeoAssistantModal
      v-show="isModalVisible"
      :field="field"
      :suggestion-text="suggestion"
      :prompt-text="prompt"
      :visible="isModalVisible"
      data-apos-test="seo-assistant-modal"
      @suggestion="suggestion = $event"
      @prompt="prompt = $event"
      @apply-suggestion="applySuggestion"
    />
    <ul
      v-show="!isModalVisible"
      class="apos-context-menu__items"
      data-apos-test="seo-assistant-menu"
      role="menu"
    >
      <AposContextMenuItem
        v-for="item in menuItems"
        :key="item.action"
        :data-apos-test-context-menu-item="item.action"
        :menu-item="item"
        :open="true"
        @clicked="openModal"
      />
    </ul>
  </AposContextMenu>
</template>

<script>
import AposFieldMetaUtilsMixin from 'Modules/@apostrophecms/doc/mixins/AposFieldMetaUtilsMixin';

export default {
  name: 'AposSeoAssistantFieldMeta',
  mixins: [ AposFieldMetaUtilsMixin ],
  emits: [ 'replace-field-value' ],
  data() {
    return {
      suggestion: '',
      prompt: '',
      isVisible: this.field?.seoAssistant === true,
      isModalVisible: false,
      menuItems: [
        {
          label: 'Make suggestions based on page content',
          name: 'suggestionMenu',
          action: 'modal'

        }
      ],
      menuButton: {
        icon: 'creation-icon',
        color: 'var(--a-base-1)',
        iconOnly: true,
        modifiers: [ 'small', 'seo' ]
      }
    };
  },
  computed: {
    menu() {
      return this.isModalVisible ? null : this.menuItems;
    }
  },
  methods: {
    openModal(item) {
      this.isModalVisible = true;
      this.$refs.menu.setDropdownPosition();
    },
    applySuggestion({ text, prompt }) {
      this.suggestion = text;
      this.prompt = prompt;
      this.$emit('replace-field-value', text);
      this.$refs.menu.hide();
    }
  }
};
</script>

<style lang="scss" scoped>
$magic-border: #0ddc14;
$magic-green: #28c730;

:deep(.apos-button--seo.apos-button--icon-only.apos-button--small) {
  padding: 4px;
  border: 1px solid $magic-border;
  background-color: var(--a-background-primary);
  color: $magic-green;
  transition: all 200ms ease-in-out;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid color.adjust($magic-border, $lightness: -10%);
    background-color: var(--a-background-primary);
    color: $magic-green;
  }
}

:deep(.apos-button--seo.apos-is-active) {
    border: 1px solid color.adjust($magic-border, $lightness: -10%);
    background-color: var(--a-background-primary);
    color: $magic-green;
}
</style>
