<template>
  <AposInputWrapper
    :uid="Math.random()"
    :field="field"
  >
    <template #body>
      <div class="apos-input-wrapper">
        <div class="apos-user-list__header">
          <span class="apos-user-list__label">
            {{ $t('apostrophe:title') }}
          </span>
          <span class="apos-user-list__label">
            {{ $t('apostrophe:username') }}
          </span>
          <span class="apos-user-list__label">
            {{ $t('apostropheAdvancedPermission:groups') }}
          </span>
        </div>
        <ul class="apos-user-list__list">
          <li
            v-for="user in userList"
            :key="user._id"
            class="apos-user-list__user"
          >
            <span class="apos-user-list__label">
              {{ user.title }}
            </span>
            <span class="apos-user-list__label">
              {{ user.username }}
            </span>
            <span class="apos-user-list__label">
              {{ formatGroups(user._groups) }}
            </span>
          </li>
        </ul>
      </div>
    </template>
  </AposInputWrapper>
</template>

<script>
export default {
  name: 'AposInputUserList',
  inject: {
    originalDoc: {
      default: () => ({
        ref: null
      })
    }
  },
  props: {
    field: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    userList: []
  }),
  computed: {
    moduleOptions() {
      return window.apos.modules[this.field.moduleName];
    }
  },
  mounted () {
    this.fetchUserList();
  },
  methods: {
    async fetchUserList() {
      try {
        this.userList = await apos.http.get(
          `${this.moduleOptions.action}/${this.originalDoc.ref._id}/users`,
          { busy: true }
        );
      } catch (error) {
        apos.notify('apostropheAdvancedPermission:errorFetchUserList', { type: 'error' });
      }
    },
    formatGroups(groups) {
      return groups
        .map(({ title }) => title.charAt(0).toUpperCase() + title.slice(1))
        .join(', ');
    }
  }
};
</script>
<style scoped lang='scss'>
.apos-input-wrapper {
  margin-top: 20px;
}

.apos-user-list {
  &__header, &__user {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  &__label {
     word-break: break-word;
  }

  &__label:first-child {
    margin-left: 5px;
  }

  &__label:not(:last-child) {
    margin-right: 5px;
  }

  &__header {
    @include type-label;

    & {
      color: var(--a-base-4);
    }
  }

  &__list {
    margin-top: 5px;
    padding-left: 0;
  }

  &__user {
    @include type-label;

    & {
      padding: 10px 0;
      border-top: solid 1px var(--a-base-9);
    }
  }

  &__user:hover {
    background-color: var(--a-base-9);
  }

  &__user:last-child {
    border-bottom: solid 1px var(--a-base-9);
  }
}
</style>
