<template>
  <AposModal
    :modal="modal"
    class="apos-imex-translate-modal"
    data-apos-test="apos-import-export-translation-modal"
    @inactive="modal.active = false"
    @show-modal="modal.showModal = true"
  >
    <template #main>
      <AposModalBody>
        <template #bodyMain>
          <AposIndicator
            icon="close-icon"
            class="apos-imex-translate-modal__close-btn"
            :icon-size="20"
            @click="close"
          />
          <h2
            v-if="content.heading"
            class="apos-imex-translate-modal__heading"
          >
            {{ content.heading }}
          </h2>
          <p
            v-if="content.description"
            class="apos-imex-translate-modal__description"
          >
            {{ content.description }}
          </p>
          <Component
            :is="content.body.component"
            v-if="content.body"
            v-bind="content.body.props"
            v-model="modelValue"
          />
          <div
            v-if="content.form"
            class="apos-imex-translate-modal__schema"
          >
            <AposSchema
              v-if="formValues"
              v-model="formValues"
              :schema="content.form.schema"
              :trigger-validation="true"
            />
          </div>
          <div class="apos-imex-translate-modal__btns">
            <AposButton
              ref="confirm"
              class="apos-imex-translate-modal__btn"
              :label="content.submitLabel"
              :icon="content.submitIcon"
              type="primary"
              :disabled="isDisabled"
              :modifiers="['block']"
              @click="submit"
            />
          </div>
        </template>
      </AposModalBody>
    </template>
  </AposModal>
</template>

<script>
export default {
  name: 'AposImportExportTranslationModal',
  props: {
    mode: {
      type: String,
      required: true
    },
    moduleName: {
      type: String,
      required: true
    }
  },
  emits: [ 'confirm-response', 'modal-result' ],
  data() {
    return {
      modal: {
        title: '',
        active: false,
        type: 'overlay',
        showModal: false,
        disableHeader: true
      },
      formValues: null,
      modelValue: null,
      lastExportedAt: null,
      busy: false
    };
  },
  computed: {
    moduleOptions() {
      return apos.modules?.[this.moduleName] ?? {};
    },
    modeAction() {
      return this.mode === 'export'
        ? `${this.moduleOptions.action}/export`
        : `${this.moduleOptions.action}/import`;
    },
    currentLanguage() {
      return apos.i18n.locales?.[apos.locale]?.label || 'English';
    },
    content() {
      switch (this.mode) {
        case 'export':
          return {
            heading: this.$t('importExportTranslation:exportManagerHeading', {
              language: this.currentLanguage
            }),
            description: this.$t('importExportTranslation:exportManagerDescription', {
              date: this.lastExportedAt ?? this.$t('importExportTranslation:exportManagerModifiedNever')
            }),
            submitLabel: this.$t('importExportTranslation:exportManagerSubmitLabel', {
              language: this.currentLanguage
            }),
            submitIcon: 'download-icon',
            form: {
              schema: [
                {
                  name: 'exportType',
                  type: 'radio',
                  label: '',
                  choices: [
                    {
                      label: this.$t('importExportTranslation:exportManagerChoiceAll'),
                      value: 'all'
                    },
                    {
                      label: this.$t('importExportTranslation:exportManagerChoiceModified'),
                      value: 'modified'
                    }
                  ]
                }
              ],
              value: {
                data: {
                  exportType: 'all'
                }
              }
            }
          };
        case 'import':
          return {
            heading: this.$t('importExportTranslation:importManagerHeading', {
              language: this.currentLanguage
            }),
            description: this.$t('importExportTranslation:importManagerDescription'),
            submitLabel: this.$t('importExportTranslation:importManagerSubmitLabel', {
              language: this.currentLanguage
            }),
            submitIcon: 'upload-icon',
            body: {
              component: 'AposImportExportTranslationFile',
              props: {
                busy: this.busy
              },
              modelValue: null
            }
          };

        default:
          throw new Error(`Unknown mode: ${this.mode}`);
      }
    },
    isDisabled() {
      if (this.busy) {
        return true;
      }
      if (this.content.form && !this.formValues) {
        return false;
      }
      if (typeof this.content.body?.modelValue !== 'undefined' && this.modelValue === null) {
        return true;
      }
      let disabled = false;
      if (this.content.form?.schema) {
        this.content.form.schema.forEach(field => {
          if (field.required && !this.formValues.data[field.name]) {
            disabled = true;
          }
        });
      }
      return disabled;
    }
  },
  async mounted() {
    this.modal.active = true;
    if (this.content.form) {
      this.formValues = JSON.parse(JSON.stringify(this.content.form.value));
    }
    if (this.content.body?.modelValue) {
      this.modelValue = this.content.body.modelValue;
    }
    await this.setLastExportedAt();
  },
  methods: {
    close() {
      if (this.busy) {
        return;
      }
      this.modal.showModal = false;
      this.$emit('modal-result', null);
    },
    async exportJson() {
      this.busy = true;
      const result = await this.requestExport();
      if (result) {
        this.modal.showModal = false;
        this.$emit('modal-result', {
          options: this.formValues.data,
          data: result
        });

      }
    },
    async importJson() {
      this.busy = true;
      const result = await this.requestImport();
      if (result) {
        this.modal.showModal = false;
        this.$emit('modal-result', {
          data: result
        });

      }
    },
    submit() {
      switch (this.mode) {
        case 'export':
          return this.exportJson();
        case 'import':
          return this.importJson();
        default:
          throw new Error(`Unknown mode: ${this.mode}`);
      }
    },
    async requestExport() {
      try {
        const text = await apos.http.get(this.modeAction, {
          qs: {
            modified: this.formValues.data.exportType === 'modified',
            text: true
          }
        });
        return text;
      } catch (e) {
        return null;
      } finally {
        this.busy = false;
      }
    },
    async requestImport() {
      try {
        const formData = new window.FormData();
        formData.append('file', this.modelValue);
        const result = await apos.http.post(this.modeAction, {
          body: formData
        });
        return result;
      } catch (e) {
        return null;
      } finally {
        this.busy = false;
      }
    },
    async setLastExportedAt() {
      try {
        const formatter = new Intl.DateTimeFormat(apos.locale, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric'
        });
        const result = await apos.http.get(`${this.moduleOptions.action}/last-exported-at`, {});
        this.lastExportedAt = result.lastExportedAt
          ? formatter.format(new Date(result.lastExportedAt))
          : null;
      } catch (e) {
        console.error(e);
        this.lastExportedAt = null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.apos-imex-translate-modal {
  z-index: $z-index-modal;
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

:deep(.apos-modal__inner) {
  inset: auto;
  max-width: 700px;
  height: auto;
}

:deep(.apos-modal__overlay) {
  .apos-modal + .apos-confirm & {
    display: block;
  }
}

:deep(.apos-modal__body) {
  padding: 40px;
}

:deep(.apos-modal__body-main) {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.apos-imex-translate-modal__heading {
  @include type-title;

  & {
    line-height: var(--a-line-tall);
    margin: 0;
  }
}

.apos-imex-translate-modal__description {
  @include type-base;

  & {
    max-width: 370px;
    line-height: var(--a-line-tallest);
  }
}

.apos-imex-translate-modal__schema {
  margin-top: $spacing-base;
}

:deep(.apos-schema .apos-field) {
  margin-bottom: $spacing-base;
}

.apos-imex-translate-modal__btns {
  width: 100%;
  margin-top: 10px;
}

.apos-imex-translate-modal__btn {
  width: 100%;
}

.apos-imex-translate-modal__close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
</style>
